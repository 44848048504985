import DashboardElements from "./DashboardElements";
import SortDropdown from "./SortDropdown";
import AllLeadsTable from "./AllLeadsTable";

import React, { useRef, useState } from "react";
const BottomContainer = ({leadsDataExcel}) => {
    const [selected, setSelected] = useState("Time");


    return (<DashboardElements.BottomContainer>
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <DashboardElements.AllLeadsHeader>
                All leads
            </DashboardElements.AllLeadsHeader>

            <SortDropdown leadsDataExcel={leadsDataExcel} selected={selected} setSelected={setSelected} />
        </div>
        <AllLeadsTable selected={selected} setSelected={setSelected}/>
    </DashboardElements.BottomContainer>
    );
}

export default BottomContainer;