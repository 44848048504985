import React from "react";
import { Route, Switch } from "react-router-dom";
import Manage from "../components/Manage";
import NewGroup from "../components/Manage/NewGroup";
import NewUser from "../components/Manage/NewUser";
import GroupDetail from "../components/Manage/GroupDetail";

const ManagePage = () => {
    return (
        <div>
            <Switch>
                <Route exact path="/manage">
                    <Manage />
                </Route>
                <Route exact path="/manage/new/group">
                    <NewGroup />
                </Route>

                <Route exact path="/manage/new/user">
                    <NewUser />
                </Route>

                <Route exact path="/manage/:group">
                    <GroupDetail />
                </Route>

                <Route exact path="/manage/:group/:user">
                    <h1>Mange User</h1>
                </Route>
            </Switch>
        </div>
    );
};

export default ManagePage;
