import { useState } from "react";
import { useHistory } from "react-router-dom";
import { logoutQuery } from "../../queries/login";
import { LogoutIcon } from "./icons";
import sidebarData from "./sidebarData";
import Sidebar from "./SidebarElements";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";

const adminOnly = ["/manage"];

const Index = () => {
    const [selected, setSelected] = useState(window.location.pathname);
    const history = useHistory();
    const [state, globalDispatch] = useGlobalContext();

    const handleLogoutClick = async () => {
        const result = await logoutQuery();
        console.log(result);
        localStorage.removeItem("user");
        globalDispatch({ type: GActions.SET_USER, payload: null });
        history.push("/login");
    };

    return (
        <Sidebar.Container>
            <Sidebar.Header>
                <img src="/icons/collegeit_logo.png" width={60} alt="logo" />
            </Sidebar.Header>

            <Sidebar.Separator />

            <Sidebar.Navigation>
                {sidebarData.map((data) => {
                    if (
                        adminOnly.includes(data.to) &&
                        state.user?.privilege !== "admin"
                    ) {
                        return null;
                    }

                    return (
                        <Sidebar.Item
                            {...data}
                            key={data.to}
                            selected={selected === data.to}
                            onClick={() => setSelected(data.to)}
                        />
                    );
                })}

                {/* <Sidebar.Separator /> */}

                {/* <Sidebar.Item
          to="/privacy"
          label={"Privacy"}
          selected={selected === "/privacy"}
          icon={<VerifiedUser />}
          onClick={() => setSelected("/privacy")}
        />
        <Sidebar.Item
          to="/tos"
          label={"Terms Of Service"}
          selected={selected === "/tos"}
          icon={<Assignment />}
          onClick={() => setSelected("/tos")}
        /> */}
            </Sidebar.Navigation>

            {/* <Sidebar.Separator /> */}

            <Sidebar.Item
                label="Logout"
                icon={<LogoutIcon />}
                button
                onClick={handleLogoutClick}
                style={{
                    marginTop: "auto",
                }}
            />
        </Sidebar.Container>
    );
};

export default Index;
