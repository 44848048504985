import styled from "@emotion/styled";

const DasboardContainer = styled.div`
    margin: 0px 3.125rem;
    padding-top: 3.125rem;
    max-width: 83rem;
    min-height: 100vh; 
    height: auto;
    background: #F5F6FA;
`;

// first container
const WelcomeText = styled.div`
    font-size:2rem;
    line-height: 1.5rem;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    color:#222;
    margin-bottom:3.125rem;
`;

const StatsBoxGrid = styled.div`
    display: flex ;
    justify-content:space-between;
    @media (max-width: 2000px) {
      width:86.85rem;
    }
    @media (max-width: 1700px) {
      width:75.75rem;
    }
    @media (max-width: 1450px) {
      width:60rem;
    }
    
`;

const StatsBox = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: space-around;
    padding: 1rem;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    border: .01px solid #e0ebeb;
    box-shadow: 6px 6px 54px 1px rgba(0, 0, 0, 0.05);
    
    @media (max-width: 2000px) {
      width: 20rem;
      height: 7rem;
    }
    @media (max-width: 1700px) {
        width: 15rem;
        height: 5.625rem;
    }
    @media (max-width: 1450px) {
      width:14rem;
      height: 5.5rem;
    }
`;

const StatsBoxTitle = styled.span`
    color: #71717A;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem; 
    letter-spacing: 1px;
    text-transform: uppercase;

    @media (max-width: 1700px) {
      font-size: 1rem;
    }
    @media (max-width: 1450px) {
    font-size: 14px;
    }
    `;

const StatsBoxCount = styled.span`
    color: #18181B;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem; 
`;

const StatsBoxPercentageChange = styled.span`
    color: #EF4444;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; 
`;

//middle container 
const MiddleContainer = styled.div`
    display: flex;
    background:transparent;
    padding:1.75rem 0;
    @media (max-width: 2000px) { 
      width:86.85rem;
    }
    @media (max-width: 1700px) {
      width:75.75rem;
      
    }
    @media (max-width: 1450px) {
      width:60rem;
    }
    justify-content:space-between;
`;

const ChartContainer = styled.div`
    background:#000000;     
    border-radius: 10px;
    background: #FFF;
    border: .01px solid #e0ebeb;
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
    @media (max-width: 2000px) { 
      width:905px;
      height:500px;
      justify-items: center;
    }
    @media (max-width: 1700px) { 
      width:797px;
      height:400px;
      justify-items: center;
    }
    @media (max-width: 1450px) {
      width:641px;
      height:350px;
      justify-items: center;
    }
    padding:1rem 2rem;
`;

const TopAmbassadorsContainer = styled.div`
    border-radius: 10px;
    background: #FFF;
    
    border: .01px solid #e0ebeb;
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
    padding:1rem 2rem;
    @media (max-width: 2000px) {
      width:452px;
      height:500px;
    }
    @media (max-width: 1700px) {
      width:383px;
      height:400px;
    }
    @media (max-width: 1450px) {
      width:294px;
      height:350px;
    }
`;

const AmbassadorsHead = styled.div`
    color: #222;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 2000px) {
      margin-bottom:0.5rem;
      font-size: 24px;
      line-height: 24px; 
    }
    @media (max-width: 1700px) {
      margin-bottom:0.5rem;
      font-size: 20px;
      line-height: 24px; 
    }
    @media (max-width: 1450px) {
      margin-bottom:0.25rem;
      font-size: 16px;
      line-height: 20px; 
    }
`;

const AmbassadorsDes = styled.div`
    color: #444;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    @media (max-width: 2000px) {
      margin-bottom:1.75rem;
      font-size: 16px;
      line-height: 22px; 
    }
    
    @media (max-width: 1700px) {
      margin-bottom:1.75rem;
      font-size: 14px;
      line-height: 22px; 
    }
  
    @media (max-width: 1450px) {
      margin-bottom:1.75rem;
      font-size: 12px;
      line-height: 18px; 
    }
`;

const AmbassadorBox = styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom:2rem;
    
`;

const AmbassadorsName = styled.div`
    color: #333;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    @media (max-width: 2000px) {
      font-size: 16px;
      }
      @media (max-width: 1700px) {
        font-size: 14px;
      }
    @media (max-width: 1450px) {
      font-size: 12px;
    }   
`;

const AmbassadorsPercentage = styled.div`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right:0.50rem;
    @media (max-width: 1700px) {
      font-size: 14px;
      }
    @media (max-width: 1450px) {
      font-size: 12px;
    }   
`;

const SeeAllAmbassadors = styled.div`
    color: #333;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem; 
    text-transform: uppercase;
    opacity: 0.5;
    display:flex;
`;


const StyledAmbassadorContainer = styled.div`
@media (max-width: 2000px) {
  height: 370px;
  overflow-y: scroll;
  max-height:90%;
}
@media (max-width: 1700px) { 
  height: 280px;
  overflow-y: scroll;
  max-height:90%;
}
  @media (max-width: 1450px) {
    height: 240px;
    overflow-y: scroll; 
    max-height:100%;
  }   
  
  ::-webkit-scrollbar {
    width: 4px; 
    height: 8px; 
  }

  ::-webkit-scrollbar-track {
    background: transparent; 
  }

  ::-webkit-scrollbar-thumb {
    background: #71717A; 
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease; 
  }

  &:hover::-webkit-scrollbar-thumb {
    opacity: 0.5; /* Set opacity to make scrollbar handle visible */
  }
  `;

const ChartHeader = styled.div`
    color: #222;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; 
    margin:0.5rem 1rem 0.5rem 0rem;
    margin-top:0.5rem;
    @media (max-width: 2000px) {
      font-size: 24px;
      line-height: 24px; 
    }
    @media (max-width: 1700px) {
      font-size: 20px;
      line-height: 24px; 
    }
    @media (max-width: 1450px) {
      font-size: 16px;
      line-height: 20px; 
    }   
    `;

const MonthBox = styled.span`
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    color: #333;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
`;

const GraphMonthBox = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top:1.25rem;
`;

const MonthButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
`;
//bottom container
const BottomContainer = styled.div`
    padding:28px 38px;
    background:#FFFFFF; 
    border-radius:10px;
    
    border: .01px solid #e0ebeb;
    @media (max-width: 2000px) {
      width:86.85rem;
    }
    @media (max-width: 1700px) {
      width:75.75rem;
    }
    @media (max-width: 1450px) {
      width:60rem;
    }
`;

const AllLeadsHeader = styled.div`
    color: #333;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
    display:flex;
    margin-top:0.5rem;
`;

const AllLeadsTable = styled.div`
border-radius: 12px 12px 0px 0px;
margin-top: 1.5rem;
@media (max-width: 2000px) {
  width:82.85rem;
}
@media (max-width: 1700px) {
  width:71.25rem;
}
@media (max-width: 1450px) {
  width:56rem;
  
}
`;

const TableThead = styled.div`
border-radius: 12px 12px 0px 0px;
background: #e8eefc;
display: flex;
flex-direction: row;
@media (max-width: 1700px) {
  width:71.25rem;
}
@media (max-width: 1450px) {
  width:56.375rem;
}
`;

const Tableh = styled.div`
    flex: 1;  
    color: #333;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 2000px) {
      padding-left: 2rem;
    }
    @media (max-width: 1700px) {
      padding-left: 2rem;
    }
    @media (max-width: 1450px) {
      padding-left: 1.25rem;
    }
`;

const Tabled = styled.div`
    color: #333;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    flex: 1;
    word-break: break-all; 
    text-align: left;
    @media (max-width: 2000px) {
      padding-left: 2rem;
    }
    @media (max-width: 1700px) {
      padding-left: 2rem;
    }
    @media (max-width: 1450px) {
      padding-left: 1.25rem;
    }
`;


const TableTr = styled.div`
    display: flex;
    justify-content: space-around;
    text-align: center;
    border-bottom: 0.4px solid rgba(151, 151, 151, 0.2);
`;

export const Dashboard = {
  WelcomeText,
  DasboardContainer,
  StatsBoxGrid,
  StatsBox,
  StatsBoxTitle,
  StatsBoxCount,
  StatsBoxPercentageChange,
  MiddleContainer,
  ChartContainer,
  TopAmbassadorsContainer,
  AmbassadorsHead,
  AmbassadorsDes,
  AmbassadorsName,
  AmbassadorsPercentage,
  AmbassadorBox,
  SeeAllAmbassadors,
  StyledAmbassadorContainer,
  ChartHeader,
  MonthBox,
  GraphMonthBox,
  MonthButtonsContainer,
  AllLeadsHeader,
  BottomContainer,
  AllLeadsTable,
  TableThead,
  Tableh,
  Tabled,
  TableTr
};

export default Dashboard;
