import { useEffect, useState } from "react";
import { Table } from "./Overview/OverviewElements";
import LeadTablePagination from "./Overview/LeadTablePagination";
import unimap from "../utils/unimap";
import moment from "moment/moment";
import DashboardElements from "../components/NewDashboard/DashboardElements";
import { useGlobalContext } from "../contexts/GlobalContext";
// import { MdDeleteOutline } from "react-icons/md";
// import { IconButton } from "@mui/material";
// import { FiCheck } from "react-icons/fi";
// import { IoIosRefresh } from "react-icons/io";
const MeetingRequestsTable = ({ leads = [], toggleMeet, deleteMeet }) => {
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(leads.length);

  const [state, dispatch] = useGlobalContext();
  useEffect(() => {
    setTotalPage(Math.ceil(leads.length / rowsPerPage));
  }, [leads]);

  const [tableHeads, setTableHeads] = useState(
    state.user?.privilege !== "admin"
      ? ["Email", "Country", "Timestamp"]
      : ["Name", "Email", "University", "Timestamp"]
  );
  const handleNextPageClick = () => {
    if (currentPage === totalPage - 1) return;
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPageClick = () => {
    if (currentPage === 0) return;
    setCurrentPage((prev) => prev - 1);
  };

  return (
    <>
      <DashboardElements.AllLeadsTable
        style={{
          width: "100%",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          border: ".01px solid #e0ebeb",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "16px 0 10px 0",
            color: "#333",
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            borderRadius: "12px 12px 0px 0px",
            background: "#E8EEFC",
            width: "100%",
          }}
        >
          {tableHeads.map((title, id) => (
            <DashboardElements.Tableh key={id}>
              {title}
            </DashboardElements.Tableh>
          ))}
        </div>

        {leads
          .slice(
            currentPage * rowsPerPage,
            currentPage * rowsPerPage + rowsPerPage
          )
          .map((conversation, id) => (
            <div key={conversation._id}>
              <DashboardElements.TableTr>
                <DashboardElements.Tabled style={{ flex: 1 }}>
                  {conversation.email}
                </DashboardElements.Tabled>
                <DashboardElements.Tabled style={{ flex: 1 }}>
                  {conversation.country}
                </DashboardElements.Tabled>
                {state.user?.privilege === "admin" && <DashboardElements.Tabled style={{ flex: 1 }}>
                  {conversation.university}
                </DashboardElements.Tabled>}
                <DashboardElements.Tabled style={{ flex: 1 }}>
                  {conversation.timestamp
                    ? moment
                        .utc(conversation.lastResponseMentor)
                        .local()
                        .format("Do MMM, YYYY")
                    : "N/A"}
                </DashboardElements.Tabled>
              </DashboardElements.TableTr>
            </div>
          ))}
      </DashboardElements.AllLeadsTable>
      <LeadTablePagination
        currentPage={currentPage + 1}
        totalPage={totalPage}
        onPreviousClick={() => handlePrevPageClick()}
        onNextClick={() => handleNextPageClick()}
      />
    </>
  );
};

export default MeetingRequestsTable;
