import styled from "@emotion/styled";

export const TwoPlane = styled.div`
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    column-gap: 20px;
`;

export const UpperForm = styled.div`
    display: flex;
    gap: 27px;
`;
