import axios from "axios";
import { BASE_URL } from "./url";

const loginAxios = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

export const getConvs = async () => {
    try {
        const result = await loginAxios.get(`/all`);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getSingleConversation = async (convId) => {
    try {
        const result = await loginAxios.get(`/conversation/${convId}`);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getLeads = async () => {
    try {
        const result = await loginAxios.get("/leads/");
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};
