import React, { useEffect, useState } from "react";
import { Table } from "../Overview/OverviewElements";
import LeadTablePagination from "../Overview/LeadTablePagination";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { getGroups } from "../../queries/groups";

const GroupTable = () => {
  const [leads, setLeads] = useState([]);
  const getGroupsWrapper = async () => {
    const res = await getGroups();
    const result = res.groups;
    setLeads(result);
  };
  useEffect(() => {
    getGroupsWrapper();
    return () => {};
  }, [leads]);
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(leads.length);

  const history = useHistory();
  useEffect(() => {
    setTotalPage(Math.ceil(leads.length / rowsPerPage));
  }, [leads]);

  const handleNextPageClick = () => {
    if (currentPage === totalPage - 1) return;
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPageClick = () => {
    if (currentPage === 0) return;
    setCurrentPage((prev) => prev - 1);
  };
  return (
    <>
      <Table.Wrapper>
        <thead>
          <tr style={{ borderBottom: "1px solid #d2d2d2" }}>
            <Table.Head>Name</Table.Head>
            <Table.Head>University</Table.Head>
            <Table.Head>Accounts</Table.Head>
            <Table.Head>Mentors</Table.Head>
          </tr>
        </thead>

        <tbody>
          {leads
            .slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
            .map((lead) => (
              <Table.Row
                key={lead._id}
                onClick={() => history.push("/manage/" + lead._id)}
              >
                <Table.Data>
                  <Tooltip title="Copy">
                    <span
                      style={{
                        fontFamily: "consolas",
                        border: "1px solid #d2d2d2",
                        padding: "2px 4px",
                        backgroundColor: "white",
                      }}
                      onClick={(e) => {
                        // stop propogation
                        e.stopPropagation();
                        // copy to clipboard
                        navigator.clipboard.writeText(lead.name);
                      }}
                    >
                      {lead.name}
                    </span>
                  </Tooltip>
                </Table.Data>
                <Table.Data>{lead.university}</Table.Data>
                <Table.Data>{lead.accountCount}</Table.Data>
                <Table.Data>{lead.mentorCount}</Table.Data>
              </Table.Row>
            ))}
        </tbody>
      </Table.Wrapper>
      <LeadTablePagination
        currentPage={currentPage + 1}
        totalPage={totalPage}
        onPreviousClick={handlePrevPageClick}
        onNextClick={handleNextPageClick}
      />
    </>
  );
};

export default GroupTable;
