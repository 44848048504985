import React from "react";

const UniversityGrouping = (props) => {
  return (
    <div style={{ background: "#F4F8FF", padding: "0.5rem 0rem 0.5rem 2rem", marginBottom:"5px" }}>
      <button
        onClick={props.open}
        style={{
          display: "flex",
          alignItems: "center", 
        }}
      >
        <span
          style={{
            color: "#333",
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            marginRight: '0.5rem', 
          }}
        >
          {props.universityName }
        </span>
        <img
          src="/AllLeadsAmbassadorButton.svg"
          alt="icon"
          style={props.imgStyle}
        />
      </button>
    </div>
  );
};

export default UniversityGrouping;
