import styled from "@emotion/styled";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const Scaffold = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
`;

const ContentStyle = styled.div`
    width: 100%;
    overflow: auto;
`;

export const Content = ({ children }) => {
    const history = useHistory();

    const smoothScrollToTop = () => {
        document?.getElementById("content")?.scrollTo({
            behavior: "smooth",
            top: "0",
        });
    };

    useEffect(() => {
        return history.listen((location) => {
            const invalid = ["/login", "forgot-password"];
            if (invalid.includes(location.pathname)) return;
            smoothScrollToTop();
        });
    }, [history]);

    return (
        <ContentStyle id="content" style={{ background: "#F5F6FA" }}>
            {children}
        </ContentStyle>
    );
};
