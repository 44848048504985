import { LoginForm, LoginTitle, SubmitButton } from "./items";
import { useHistory } from "react-router-dom";

const GoodToGo = () => {
  const history = useHistory();

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <LoginForm>
      <LoginTitle>Changed Successfully</LoginTitle>
      <div
        style={{
          display: "flex",
          height: "60%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SubmitButton onClick={() => goToLogin()}>Continue Login</SubmitButton>
      </div>
    </LoginForm>
  );
};

export default GoodToGo;
