import { useEffect } from "react";
import { Pagination } from "./OverviewElements";

const LeadTablePagination = ({
  currentPage,
  totalPage,
  onNextClick,
  onPreviousClick,
}) => {
  if (totalPage === 0) return null;

  return (
    <Pagination.Wrapper>
      <Pagination.LeftArrow onClick={onPreviousClick} />
      <Pagination.CurrentPage page={currentPage} />
      of
      <Pagination.TotalPage page={totalPage} />
      <Pagination.RightArrow onClick={onNextClick} />
    </Pagination.Wrapper>
  );
};

export default LeadTablePagination;
