import React, { useEffect, useState } from "react";
import { Container, Header, Heading } from "../Overview/OverviewElements";
import { Button } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { getGroups } from "../../queries/groups";
import { Loading } from "../Overview";
import GroupTable from "./GroupTable";
import { useHistory } from "react-router-dom";

const Manage = () => {
  const [state, dispatch] = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    document.title = "Manage | CollegeIt";

    if (!state.groups || state.groups.length === 0) {
      setLoading(true);
      // fetch groups
      getGroups()
        .then((res) => {
          console.log(res);
          dispatch({
            type: GActions.SET_GROUPS,
            payload: res.groups,
          });
          localStorage.setItem("groups", res.groups);
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);
  return (
    <Container>
      <Header>
        <Heading>Manage</Heading>
        <div className="flex">
          <Button
            variant="contained"
            startIcon={<AccountBalanceIcon />}
            style={{
              fontFamily: "Outfit",
              textTransform: "capitalize",
              marginRight: "10px",
              justifyContent: "start",
            }}
            onClick={() => {
              history.push("/manage/new/group");
            }}
          >
            New Group
          </Button>
          <Button
            variant="contained"
            startIcon={<PersonAddAlt1Icon />}
            style={{
              fontFamily: "Outfit",
              textTransform: "capitalize",
              marginRight: "10px",
              justifyContent: "start",
              background: "green",
            }}
            onClick={() => {
              history.push("/manage/new/user");
            }}
          >
            New User
          </Button>
        </div>
      </Header>
      {loading ? <Loading /> : <GroupTable leads={state.groups} />}
    </Container>
  );
};

export default Manage;
