import axios from "axios";
import { SERVICE_URL, BASE_URL, NEW_SERVICE_URL } from "./url";

const apiService = axios.create({
  baseURL: SERVICE_URL,
});

const platformApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

const newApiService = axios.create({
  baseURL: NEW_SERVICE_URL,
});

export const addMentor = (mentor) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await platformApi.post("/mentors/new", mentor);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });

export const updateMentor = (mentor) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await platformApi.post("/mentors/update", mentor);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });

export const toggleMentorVisibility = (mentorId) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await platformApi.post(`/mentors/toggleActive`, {
        _id: mentorId,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });

export const deleteMentorById = (mentorId) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await platformApi.post(`/mentors/delete`, {
        _id: mentorId,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });

export const getMentorsByGroup = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await platformApi.get("/mentors");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getMentors = () =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await apiService.get("/mentors/info");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });

export const getAllLeadsCount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await newApiService.get("/all-lead");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllLeadsCountLast30Days = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await newApiService.get("/leads-last-30-days");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllMentorsPerformance = (university) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await newApiService.post("/v1/all-mentors-performance", {
        university,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
