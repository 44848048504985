import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Container = styled.section`
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    width: 330px;
    padding: 2.6rem 1.6rem;
    background: #FFFFFF;
`;

const Header = styled.div`
    margin-top:3.125rem;
    margin-bottom:3.125rem;
    margin-left:1rem;
`;

const Separator = styled.span`
    display: block;
    width: 100%;
    height: 0.6px;
    background-color:#E0E0E0;
    margin-bottom:2.5rem;
    opacity: 0.8;
`;

const Navigation = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
    height: 100%;
`;

const iconColor = {
    selected: "#FFF",
    unselected: "#000",
};

const textColor = {
    selected: "#FFF",
    unselected: "#333333",
};

const itemColor = {
    selected: "#4880FF",
    unselected: "#FFFFFF",
};

const NavButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    width: 100%;

    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    min-height: 42px;
    padding: 8px 8px 8px 16px;
    transform: translateY(115%);
    color: ${(props) =>
        props.selected ? textColor.selected : textColor.unselected};

    background-color: ${(props) =>
        props.selected ? itemColor.selected : itemColor.unselected};
    transition: 250ms;
    border-radius: 6px;

    & > svg {
        color: ${(props) =>
        props.selected ? iconColor.selected : iconColor.unselected};
        transition: 250ms;
    }

    &:hover {
        background-color: ${itemColor.selected};
        color: ${textColor.selected};
        

        & > svg {
            color: ${iconColor.selected};
        }
    }

    &:active {
        transform: translate(0);
        box-shadow: none;
    }
`;
const NavLink = styled(Link)`
    text-decoration: none;
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    min-height: 42px;
    padding: 8px 8px 8px 16px;
    color: ${(props) =>
        props.selected ? textColor.selected : textColor.unselected};

    background-color: ${(props) =>
        props.selected ? itemColor.selected : itemColor.unselected};
    transition: 250ms;
    border-radius: 6px;

    & > svg {
        color: ${(props) =>
        props.selected ? iconColor.selected : iconColor.unselected};
        transition: 250ms;
    }

    &:hover {
        background-color: ${itemColor.selected};
        color: ${textColor.selected};
     

        & > svg {
            color: ${iconColor.selected};
        }
    }
`;

const ItemText = styled.span`
    margin-left: 1.6rem;
    font-family: Outfit;
    font-size: 17px;
    font-weight: 600;
    transition: 50ms;

    
`;

const Item = ({
    to,
    label,
    icon = null,
    selected = false,
    button = false,
    onClick ,
    style,
}) => {
    console.log(button);
    if (button) 
        return (
            <NavButton onClick={onClick} style={style}>
                {icon}
                <ItemText onClick={onClick}>{label}</ItemText>
            </NavButton>
        );
    return (
        <NavLink to={to} selected={selected} onClick={onClick} style={style}>
            {icon}
            <ItemText>{label}</ItemText>
        </NavLink>
    );
};

export const Sidebar = {
    Container,
    Header,
    Separator,
    Navigation,
    NavButton,
    ItemText,
    Item,
};

export default Sidebar;
