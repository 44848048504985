import { CgProfile } from "react-icons/cg";
import {OverviewIcon,InsigthsIcon,CallLogo,MeetingRequestIcon} from "./icons";

const arr = [
    {
        label: "Overview",
        to: "/",
        icon: <OverviewIcon style={{ fontSize: "22px" }}/>,
    },
    {
        label: "Meeting requests",
        to: "/meeting-requests",
        icon: <MeetingRequestIcon style={{ fontSize: "22px" }} />,
    },
    {
        label: "Mentors",
        to: "/mentors",
        icon: <CgProfile style={{ fontSize: "22px" }} />,
    },
    {
        label: "Insights",
        to: "/insights",
        icon: <InsigthsIcon style={{ fontSize: "22px" }} />,
    },
    {
        label: "Calls",
        to: "/callOverview",
        icon: <CallLogo style={{ fontSize: "22px" }} />,
    },


];
export default arr;
