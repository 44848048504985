import DashboardElements from "./DashboardElements";
import BarGraph from "./BarGraph";
const BarGraphContainer = () => {
    return (
        <div>
            <DashboardElements.ChartContainer>
                <div style={{ display: 'flex' }}>

                    <DashboardElements.ChartHeader>
                    Global visitor distribution by country
                    </DashboardElements.ChartHeader>

                </div>
                <BarGraph />
                {/* <BarGraphTimebar/> */}
            </DashboardElements.ChartContainer>
        </div>
    );
}

export default BarGraphContainer;