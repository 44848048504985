import { MentorsIcon, OutreactIcon, OverviewIcon } from "./icons";
import { MdInsights, MdOutlineMeetingRoom } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const arr = [
    {
        label: "Overview",
        to: "/",
        icon: <OverviewIcon />,
    },
    {
        label: "Meeting requests",
        to: "/meeting-requests",
        icon: <MdOutlineMeetingRoom style={{ fontSize: "24px" }} />,
    },
    {
        label: "Mentors",
        to: "/mentors",
        icon: <CgProfile style={{ fontSize: "24px" }} />,
    },
    {
        label: "Insights",
        to: "/insights",
        icon: <MdInsights style={{ fontSize: "24px" }} />,
    },
    {
        label: "Manage",
        to: "/manage",
        icon: <ManageAccountsIcon style={{ fontSize: "24px" }} />,
    },

    // {
    //   label: "Outreach",
    //   to: "/outreach",
    //   icon: <OutreactIcon />,
    // },
];
export default arr;
