import { LoginForm, LoginTitle, SubmitButton } from "./items";

const Continue = ({ goToDashboard }) => {
  return (
    <LoginForm>
      <LoginTitle>Changed Successfully</LoginTitle>
      <div
        style={{
          display: "flex",
          height: "60%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SubmitButton onClick={() => goToDashboard()}>
          Continue to Dashboard
        </SubmitButton>
      </div>
    </LoginForm>
  );
};

export default Continue;
