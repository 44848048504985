import { TextField } from "@mui/material";
import { useState } from "react";
import { PropagateLoader } from "react-spinners";
import { ForgotLink, LoginForm, LoginTitle, SubmitButton } from "./items";

const ActualLoginForm = ({ onSubmit, error }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    await onSubmit({
      email,
      password,
    });
    setSubmitting(false);
  };

  return (
    <LoginForm onSubmit={handleFormSubmit}>
      <LoginTitle>Sign In</LoginTitle>
      <TextField
  fullWidth
  margin="dense"
  label="Email"
  color="secondary"
  type="email"
  variant="standard"
  error={error.email !== ""}
  helperText={error.email}
  value={email}
  onChange={handleEmailChange}
  sx={{ fontFamily: 'Nunito Sans, sans-serif' }}
  required
/>

      <TextField
        fullWidth
        margin="dense"
        label="Password"
        color="secondary"
        type="password"
        variant="standard"
        value={password}
        error={error.password !== ""}
        helperText={error.password}
        onChange={handlePasswordChange}
        sx={{ fontFamily: 'Nunito Sans, sans-serif' }}
        required
      />
      {submitting ? (
        <ButtonLoading />
      ) : (
        <SubmitButton type="submit" disabled={submitting}>
          Continue
        </SubmitButton>
      )}

      <div
        style={{
          marginTop: "2rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ForgotLink to={"/forgot-password"}>Forgot Password ?</ForgotLink>
      </div>
    </LoginForm>
  );
};

export const ButtonLoading = ({ color = "#9b00ea", marginTop = "1.6rem" }) => {
  return (
    <div
      style={{
        marginTop: marginTop,
        width: "100%",
        height: "43px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: 'Nunito Sans, sans-serif',
      }}
    >
      <PropagateLoader color={color} />
    </div>
  );
};

export default ActualLoginForm;
