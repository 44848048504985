import { Route, Switch } from "react-router-dom";
import { SessionInfoProvider } from "../contexts/SessionsContext";

import Footer from "./Footer";

import PrivacyPage from "../pages/Privacy";
import TosPage from "../pages/Tos";
import Sidebar from "../components/Sidebar";
import NewSidebar from "../components/NewSidebar"
import { Content, Scaffold } from "./PageStructure";
import MentorsPage from "../pages/MentorsPage";
import Outreach from "../pages/Outreach";
import OverviewPage from "../pages/OverviewPage";
import LoginPage from "../pages/LoginPage";
import { useEffect, useState } from "react";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";
import { PropagateLoader } from "react-spinners";
import MeetingRequests from "../pages/MeetingRequests";
import InsightsPage from "../pages/InsightsPage";
import CallOverviewPage from "../pages/CallOverviewPage";

import TestConv from "./TestConv/TestConv";
import { useGlobalContext } from "../contexts/GlobalContext";
import ManagePage from "../pages/ManagePage";
import NewDashboard from "../pages/NewDashboard";

function App() {
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [state] = useGlobalContext();

    useEffect(() => {
        console.log("user", state.user);
        localStorage.setItem("user", JSON.stringify(state.user));
            if (!state.user) history.push("/login");
        setLoading(false);
    }, [state.user, history]);

    if (loading)
        return (
            <Loading>
                <PropagateLoader color="#b200f1" />
            </Loading>
        );

    return (
        <div className="App">
            <SessionInfoProvider>
                <Switch>
                    <Route exact path="/">
                        <WithNewSidebar>
                            <NewDashboard />
                        </WithNewSidebar>
                    </Route>
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
                    <Route exact path="/forgot-password">
                        <ForgotPasswordPage />
                    </Route>
                    <Route path="/mentors">
                        <WithNewSidebar>
                            <MentorsPage />
                        </WithNewSidebar>
                    </Route>
                    <Route exact path="/insights">
                        <WithNewSidebar>
                            <InsightsPage />
                        </WithNewSidebar>
                    </Route>

                    <Route exact path="/test">
                        <TestConv />
                    </Route>

                    <Route exact path="/meeting-requests">
                        <WithNewSidebar>
                            <MeetingRequests />
                        </WithNewSidebar>
                    </Route>
                    <Route exact path="/outreach">
                        <WithSidebar>
                            <Outreach />
                        </WithSidebar>
                    </Route>
                    <Route path="/manage">
                        <WithSidebar>
                            <ManagePage />
                        </WithSidebar>
                    </Route>
                    <Route exact path="/newDashboard">
                        <WithNewSidebar>
                            <NewDashboard />
                        </WithNewSidebar>
                    </Route>
                    <Route exact path="/privacy">
                        <WithSidebar>
                            <PrivacyPage />
                            <Footer />
                        </WithSidebar>
                    </Route>
                    <Route exact path="/tos">
                        <WithSidebar>
                            <TosPage />
                            <Footer />
                        </WithSidebar>
                    </Route>
                    <Route exact path="/callOverview">
                        <WithNewSidebar>
                        <CallOverviewPage />
                        </WithNewSidebar>
                    </Route>


                    {/* <Route>
                        <Redirect to="/" />
                    </Route> */}
                </Switch>
            </SessionInfoProvider>
        </div>
    );
}

const WithSidebar = ({ children }) => {
    return (
        <Scaffold>
            <Sidebar />
            <Content>{children}</Content>
        </Scaffold>
    );
};

const WithNewSidebar = ({ children }) => {
    return (
        <Scaffold>
            <NewSidebar />
            <Content>{children}</Content>
        </Scaffold>
    );
};

export default App;
