import axios from "axios";
import { BASE_URL } from "./url";

const ax = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

export const getMeetings = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await ax.get("/meetings");
            resolve(res.data);
        } catch (error) {
            console.log(error);
            reject(error.response.data);
        }
    });
};

// export const toggleMeetingStatus = (id) => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             const res = await ax.post(`/meet/toggle`, {
//                 _id: id,
//             });
//             resolve(res.data);
//         } catch (error) {
//             console.log(error);
//             reject(error.response.data);
//         }
//     });
// };

// export const deleteMeeting = (id) => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             const res = await ax.post(`/meet/delete`, { _id: id });
//             resolve(res.data);
//         } catch (error) {
//             console.log(error);
//             reject(error.response.data);
//         }
//     });
// };
