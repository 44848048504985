import React from "react";
import { Table } from "../Overview/OverviewElements";
import { Tooltip } from "@mui/material";

const UserTable = ({ leads = [] }) => {
    if (!leads || leads.length === 0) {
        return <div className="font-[Outfit] mt-4 text-xl">No Users Found</div>;
    }

    return (
        <Table.Wrapper style={{ marginTop: "16px" }}>
            <thead>
                <tr style={{ borderBottom: "1px solid #d2d2d2" }}>
                    <Table.Head>Id</Table.Head>
                    <Table.Head>Name</Table.Head>
                    <Table.Head>Email</Table.Head>
                    <Table.Head>Password reset code</Table.Head>
                </tr>
            </thead>
            <tbody>
                {leads.map((user) => {
                    return (
                        <Table.Row key={user._id}>
                            <Table.Data>
                                <Tooltip title="Copy">
                                    <span
                                        style={{
                                            fontFamily: "consolas",
                                            border: "1px solid #d2d2d2",
                                            padding: "2px 4px",
                                            backgroundColor: "white",
                                        }}
                                        onClick={(e) => {
                                            // stop propogation
                                            e.stopPropagation();
                                            // copy to clipboard
                                            navigator.clipboard.writeText(
                                                user._id
                                            );
                                        }}
                                    >
                                        {user._id}
                                    </span>
                                </Tooltip>
                            </Table.Data>
                            <Table.Data>{user.name}</Table.Data>
                            <Table.Data>{user.email}</Table.Data>
                            <Table.Data>
                                {user.passwordResetCode || "none"}
                            </Table.Data>
                        </Table.Row>
                    );
                })}
            </tbody>
        </Table.Wrapper>
    );
};

export default UserTable;
