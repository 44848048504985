import React from "react";
import { Container, Header, Heading } from "../Overview/OverviewElements";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Link, useParams, useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import { TwoPlane } from "./NewMentorElements";
import NewMentorForm from "./NewMentorForm";
import Preview from "./Preview";
import { useEffect } from "react";
import { getMentors, updateMentor } from "../../queries/mentor";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";

const EditMentor = () => {
    const [mentor, setMentor] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const { id } = useParams();

    const [state, dispatch] = useGlobalContext();

    useEffect(() => {
        if (!state.mentors) {
            setLoading(true);
            getMentors()
                .then((data) => {
                    const fmentor = data.find((mentor) => mentor._id === id);
                    if (!fmentor) history.push("/mentors");
                    setMentor(fmentor);
                    dispatch({
                        type: GActions.SET_MENTORS,
                        payload: data,
                    });
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(true);
            const fmentor = state.mentors.find((mentor) => mentor._id === id);
            if (!fmentor) history.push("/mentors");
            setMentor(fmentor);
            setLoading(false);
        }
    }, []);

    const onMentorUpdateSubmit = async (e) => {
        e.preventDefault();
        console.log(mentor);
        try {
            const response = await updateMentor(mentor);
            console.log(response);
            dispatch({
                type: GActions.SET_SNACKBAR,
                payload: {
                    message: "Mentor updated successfully",
                    severity: "success",
                },
            });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Container>
            <Header>
                <Heading style={{ display: "flex", alignItems: "center" }}>
                    <BackButton />
                    Edit mentor
                </Heading>
            </Header>
            {loading && <div>Loading...</div>}
            {!loading && (
                <TwoPlane>
                    <NewMentorForm
                        update
                        mentor={mentor}
                        setMentor={setMentor}
                        onFormSubmit={onMentorUpdateSubmit}
                    />
                    <Preview mentor={mentor} />
                </TwoPlane>
            )}
        </Container>
    );
};

const BackButton = () => (
    <Link to="/mentors">
        <IconButton>
            <MdOutlineArrowBackIosNew
                style={{
                    fontSize: "30px",
                    color: "#5f00d8",
                }}
            />
        </IconButton>
    </Link>
);

export default EditMentor;
