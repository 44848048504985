import styled from "@emotion/styled";
import { IconButton, keyframes, MenuItem, Select } from "@mui/material";

export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
`;

export const HeadingWrapper = styled.div`
    background: ${(props) => props.headerColor};
    box-sizing: border-box;
    width: 100%;
    padding: 0.6rem 1.3rem 0.2rem 1.3rem;
`;

export const Heading = styled.h1`
    color: white;
    font-family: ${(props) => props.fontFamily};
    font-size: 1.4rem;
    letter-spacing: 0.1px;
    line-height: 23px;
    font-weight: 700;
    margin: 0.6rem 0;
    text-align: start;
`;

export const SubHeadingWrapper = styled.div`
    background: #fff;
    box-sizing: border-box;
    width: 100%;
    padding: 0.4rem 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 250ms;
    z-index: 200;
    box-shadow: 0 0 2px black;

    &:hover {
        background-color: #ddd;
    }
    &:active {
        transform: scale(0.98);
    }
`;

export const SubHeading = styled.h4`
    color: #131313;
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};
    letter-spacing: 0.3px;
    line-height: 15px;
    font-weight: 500;
    margin: 0.6rem 0;
    /* text-shadow: 0 0 2px #888; */
`;

export const MentorCardWrapper = styled.div`
    width: 100%;
    flex-grow: 1;
    background: #f3f2f2;
    box-sizing: border-box;
    max-height: 71.1%;
    overflow: auto;
    display: flex;
    padding: 0 1rem;
    flex-direction: column;

    @media (max-height: 850px) {
        max-height: 68%;
    }
    @media (max-height: 800px) {
        max-height: 66%;
    }
    @media (max-height: 712px) {
        max-height: 64%;
    }
    @media (max-height: 650px) {
        max-height: 60%;
    }
    @media (max-height: 600px) {
        max-height: 53%;
    }
`;

export const MentorCardContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

const Card = styled.div`
    background: ${(props) => (props.selected ? "#d3ebffcc" : "white")};
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    padding: 0.8rem 1rem;
    margin-top: ${(props) => (props.selected ? "2rem" : "1rem")};
    border-radius: 1rem;
    box-shadow: ${(props) =>
        props.selected
            ? `rgb(23 93 207 / 20%) 0px 2px 8px 0px, 0px 0px 2px #1949a1`
            : `rgb(99 99 99 / 20%) 0px 2px 8px 0px, 0px 0px 2px #d5d5d5, 0 0 2px #888888`};
    font-family: ${(props) => props.fontFamily};
    border: ${(props) =>
        props.selected ? "3px solid rgb(66, 133, 244)" : "3px solid white"};
    transition: 250ms;

    &:hover {
        box-shadow: ${(props) =>
            props.selected
                ? `rgb(16 57 177 / 12%) 0px 2px 4px 0px, rgb(49 79 175 / 32%) 0px 2px 16px 0px`
                : `rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px`};
    }

    /* arrangemetns */
    display: flex;

    @media (max-width: 400px) {
        padding: 0.6rem 0.5rem;
    }
`;

const AvatarImageWrapper = styled.div`
    height: ${(props) => (props.selected ? "75px" : "70px")};
    min-width: ${(props) => (props.selected ? "75px" : "70px")};
    position: relative;
    background: ${(props) => (props.selected ? "white" : "#777")};
    border-radius: 50%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 250ms;
    box-shadow: ${(props) =>
        props.selected ? "0 0 3px #08234f" : "0 0 2px #999"};

    @media (max-width: 400px) {
        height: ${(props) => (props.selected ? "54px" : "50px")};
        min-width: ${(props) => (props.selected ? "54px" : "50px")};
    }
`;

const AvatarImage = styled.img`
    height: ${(props) => (props.selected ? "70px" : "65px")};
    border-radius: 50%;
    transition: 250ms;
    user-select: none;
    box-shadow: 0 0 0
        ${(props) => (props.selected ? "0px #d3ebffcc" : "3px white")};

    @media (max-width: 400px) {
        height: ${(props) => (props.selected ? "52px" : "48px")};
    }
`;

const Image = ({ src, selected }) => {
    return (
        <AvatarImageWrapper selected={selected}>
            <AvatarImage selected={selected} src={src} />
        </AvatarImageWrapper>
    );
};

const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => (props.selected ? "0.7rem" : `1.3rem`)};

    @media (max-width: 400px) {
        margin-left: ${(props) => (props.selected ? "0.3rem" : `0.5rem`)};
    }
`;

const Title = styled.h3`
    margin: 0;
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => (props.selected ? "1.3rem" : "1.1rem")};
    font-weight: 700;
    display: block;
    transition: 200ms;
    color: #03062b;
    user-select: none;
    margin-top: 0.4rem;

    @media (max-width: 400px) {
        font-size: ${(props) => (props.selected ? "1.15rem" : "0.9rem")};
    }
`;

const Details = styled.p`
    margin: 0;
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => (props.selected ? "0.8rem" : "0.75rem")};
    user-select: none;
    margin-top: 0.2rem;
    @media (max-width: 400px) {
        font-size: ${(props) => (props.selected ? "0.75rem" : "0.72rem")};
    }
`;

const Action = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-left: auto;
    padding-left: 0.5rem;
    flex-direction: column;
`;

const Button = styled(IconButton)`
    border: 2px solid ${(props) => props.iconcolor || "#0077b5"};
    padding: 0.4rem;
    transition: 250ms;
    background-color: ${(props) =>
        props.selected ? props.iconcolor : "white"};
    & > svg {
        color: ${(props) =>
            props.selected ? "white" : props.iconcolor || "#0077b5"};
        transition: 250ms;
        pointer-events: none;
    }

    &:hover {
        background-color: ${(props) =>
            props.selected ? "transparent" : props.iconcolor || "#0077b5"};
        & > svg {
            color: ${(props) =>
                props.selected ? props.iconcolor || "#0077b5" : "white"};
        }
    }
    @media (max-width: 400px) {
        padding: ${(props) => (props.selected ? "0.15rem" : "0.18rem")};
        & > svg {
            font-size: ${(props) => (props.selected ? "21px" : "24px")};
        }
    }
`;

const DateTopRight = styled.div`
    position: absolute;
    top: -18%;
    right: 14px;
    padding: 2px 6px;
    background-color: #5da2f4;
    color: white;
    font-family: Outfit;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #126edb;
    box-shadow: inset 0px 0px 8px #368ff7, 0px 0px 2px 1px rgb(66, 133, 244);
`;

export const MentorCardElement = {
    Card,
    Image,
    Body,
    Title,
    Details,
    Action,
    Button,
    DateTopRight,
};

export const StudentFormWrapper = styled.div`
    background: white;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
`;

export const FormSubmitButton = styled(Button)`
    background-color: #04b044;
    text-transform: none;
    border-radius: 16px;
    font-size: 0.9rem;
    font-family: ${(props) => props.fontFamily};
    font-weight: 400;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.0001em;
    border: none;
    outline: none;
    color: white;
    padding: 0.4rem 1.6rem;

    &:hover {
        background-color: #128c7e;
    }
    @media (max-width: 400px) {
        padding: 0.4rem 0.6rem;
    }
`;

export const InfoDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 0.5rem;
    text-align: center;
    font-size: 0.8rem;
    font-family: ${(props) => props.fontFamily};
    color: #777;
`;

export const InfoLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: #777;

    margin-left: 0.3ch;

    &:hover {
        text-decoration: underline;
        color: #666;
    }
`;

export const StartChatButton = styled(Button)`
    background-color: #04b044;
    text-transform: none;
    border-radius: 6px;
    width: 300px;
    margin: 12px 0;
    font-size: 0.9rem;
    font-family: ${(props) => props.fontFamily};
    font-weight: 400;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.0001em;
    border: none;
    outline: none;
    color: white;
    padding: 0.4rem 1.6rem;

    &:hover {
        background-color: #128c7e;
    }
    @media (max-width: 400px) {
        padding: 0.4rem 0.6rem;
    }
`;

export const FilterFormContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
`;

export const FilterFormHeadingWrapper = styled.div`
    background-color: #3181a2;
    display: flex;
    width: 100%;
    padding: 7px 12px;
    align-items: center;
    box-shadow: 0 0 5px black;
    z-index: 20;
`;

export const FilterFormHeading = styled.div`
    font-family: Outfit;
    color: white;
    font-size: 1.3rem;
    letter-spacing: 0.7px;
`;
export const FilterFormBody = styled.div`
    background-color: white;
    flex-grow: 1;
    position: relative;
    padding: 30px 28px;
`;

export const FilterFormLabel = styled.div`
    font-family: Outfit;
    font-size: 19px;
    margin: 12px 0;
`;

export const FilterSelect = styled(Select)`
    border-radius: 8px;
    font-size: 16px;
    font-family: Outfit;
`;

export const FilterMenuItem = styled(MenuItem)`
    font-family: Outfit;
    font-size: 14px;
    min-height: unset;
`;

export const FilterButton = styled(Button)`
    position: absolute;
    font-family: Outfit;
    font-size: 16px;
    background-color: #3181a2;
    transition: 125ms;
    bottom: 30px;
    color: white;
    border-radius: 8px;
    font-weight: 300;
    width: 120px;

    &:hover {
        background-color: #18556e;
    }
`;

const pulse = keyframes`
  0% {
    background: #bcbcbc;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #bcbcbc;
  }
`;

const lightpulse = keyframes`
  0% {
    background: #cacaca;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #cacaca;
  }
`;

const LoadingImgPlaceholder = styled.div`
    display: block;
    background: #bababa;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    margin-right: 11px;
    align-self: center;
    animation: ${pulse} 2s infinite ease-in-out;
    animation-delay: 50ms;
`;

const LoadingTextWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 4px 0 4px 6px;
    gap: 4px;
`;

const LoadingHeadingPlaceholder = styled.div`
    height: 1.3rem;
    width: 100%;
    background-color: #bababa;
    margin-bottom: 4px;
    animation: ${pulse} 2s infinite ease-in-out;
    animation-delay: 250ms;
`;

const LoadingDetailsPlaceholder = styled.div`
    height: 0.7rem;
    width: 100%;
    background-color: lightgray;
    animation: ${lightpulse} 2s infinite ease-in-out;
    animation-delay: 100ms;
    opacity: 0.8;
`;

export const LoadingCard = () => {
    return (
        <MentorCardElement.Card>
            <LoadingImgPlaceholder />
            <LoadingTextWrapper>
                <LoadingHeadingPlaceholder />
                <LoadingDetailsPlaceholder />
                <LoadingDetailsPlaceholder />
            </LoadingTextWrapper>
        </MentorCardElement.Card>
    );
};
