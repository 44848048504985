import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga";
import { Typography, Grid, Stack } from "@mui/material";
import SessionInfoContext from "../contexts/SessionsContext";

const PrivacyPage = (params) => {
  const isMobile = useContext(SessionInfoContext);

  const sessionId = useParams()["id"];

  ReactGA.initialize("UA-208900493-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Grid container paddingBottom={7}>
      <Grid xs={1}></Grid>
      <Grid xs={10}>
        <Stack spacing={2}>
          <Typography
            sx={{ fontSize: 45, fontWeight: "bold" }}
            variant="h1"
            mt={6}
            mb={0.5}
          >
            CollegeIt Privacy Policy
          </Typography>
          <Typography variant="body1">
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or use any service
            provided at www.collegeit.org (the “Site”).
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            PERSONAL INFORMATION WE COLLECT{" "}
          </Typography>
          <Typography variant="body1">
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically-collected information as “Device Information.”
            <br />
            Additionally when you interact and avail of services provided by
            CollegeIt, including CollegeIt Connect, we collect certain
            information from you, including your name, email address, and phone
            number. We refer to this information as “Service Information.”
            <br />
            When we talk about “Personal Information” in this Privacy Policy, we
            are talking both about Device Information and Service Information.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            HOW DO WE USE YOUR PERSONAL INFORMATION?
          </Typography>
          <Typography variant="body1">
            We use the Service Information that we collect generally to
            facilitate interactions between you and students, alumni, and
            university officials from different institutions. Specifically, we
            use the Service Information to:
            <ul>
              <li>Communicate with you; </li>
              <li>
                Provide you with updates on new events, features, content, and
                launches
              </li>
              <li>Share calendar invites for live sessions </li>
            </ul>
            We use the Device Information that we collect to help us improve and
            optimize our Site (for example, by generating analytics about how
            our customers browse and interact with the Site, and to assess the
            success of our marketing and advertising campaigns).
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            SHARING YOUR PERSONAL INFORMATION
          </Typography>
          <Typography variant="body1">
            We collect your Personal Information on behalf of educational
            institutions such as universities and colleges, and your Personal
            Information may be shared with such institutions. <br />
            We also use Google Analytics to help us understand how our customers
            use the Site--you can read more about how Google uses your Personal
            Information:
            <a href="https://www.google.com/intl/en/policies/privacy/">here</a>.
            You can also opt-out of Google Analytics{" "}
            <a href={"https://tools.google.com/dlpage/gaoptout"}> here </a>.{" "}
            <br />
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            YOUR RIGHTS{" "}
          </Typography>
          <Typography variant="body1">
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below. <br />
            Additionally, if you are a European resident we note that we are
            processing your information in order to provide services which are
            listed on the Site, or otherwise to pursue our legitimate business
            interests listed above. Additionally, please note that your
            information will be transferred outside of Europe, including to
            Canada and the United States.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            DATA RETENTION
          </Typography>
          <Typography variant="body1">
            When you avail of services through the Site, we will maintain your
            Service Information for our records unless and until you ask us to
            delete this information.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            CHANGES
          </Typography>
          <Typography variant="body1">
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.{" "}
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            CONTACT US{" "}
          </Typography>
          <Typography variant="body1">
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e-mail at{" "}
            <a href="mailto:info@collegeit.org">info@collegeit.org</a>.
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};

export default PrivacyPage;
