import Mentor from "../components/Mentors";
import EditMentor from "../components/Mentors/EditMentor";
import NewMentor from "../components/Mentors/NewMentor";
import { MentorFilterProvider } from "../contexts/MentorFilterContext";
import { Route, Switch } from "react-router-dom";

const MentorsPage = () => {
    return (
        <MentorFilterProvider>
            <Switch>
                <Route exact path={"/mentors"}>
                    <Mentor />
                </Route>
                <Route exact path={"/mentors/new"}>
                    <NewMentor />
                </Route>
                <Route exact path={"/mentors/:id"}>
                    <EditMentor />
                </Route>
            </Switch>
        </MentorFilterProvider>
    );
};

export default MentorsPage;
