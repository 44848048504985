import React, { useEffect, useState } from "react";
import { Avatar, Chip, IconButton, Tooltip } from "@mui/material";
import { AiOutlineMore } from "react-icons/ai";
import moment from "moment";
import { BiCopy } from "react-icons/bi";
import AiSummary from "./AiSummary";
import { useGlobalContext } from "../../contexts/GlobalContext";

import axios from "axios";
import Transcript from "./Transcript";

const Analysis = ({ onGenerateAiSummaryButtonClick }) => {
  const [state] = useGlobalContext();

  if (!state.selectedConversationRetell) return <PlaceHolder />;
  const responseStudent = state.selectedConversationRetell.lastResponseStudent;
  const responseMentor = state.selectedConversationRetell.lastResponseMentor;
  const lastResponseDateStudent = new Date(responseStudent);
  const lastResponseDateMentor = new Date(responseMentor);
  let finalTime;
  if (
    lastResponseDateMentor !== undefined &&
    lastResponseDateStudent !== undefined
  ) {
    if (lastResponseDateMentor > lastResponseDateStudent)
      finalTime = lastResponseDateMentor;
    else finalTime = lastResponseDateStudent;
  } else if (
    lastResponseDateMentor === undefined &&
    lastResponseDateStudent !== undefined
  ) {
    finalTime = lastResponseDateStudent;
  } else if (
    lastResponseDateStudent === undefined &&
    lastResponseDateMentor !== undefined
  ) {
    finalTime = lastResponseDateMentor;
  } else {
    finalTime = "final";
  }

  return (
    <div className="w-full bg-white shadow-lg">
      <div className="w-full bg-[#4880FF] px-8 py-2 relative flex">
        <Avatar
          style={{
            height: "100px",
            width: "100px",
            fontSize: "65px",
            transform: "translateY(50%)",
            backgroundColor: "#4880FF",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.44)",
          }}
        >
          {state.selectedConversationRetell?.name[0]}
        </Avatar>
        <span className="font-[Outfit] text-4xl font-semibold mt-auto ml-6 text-white">
          {state.selectedConversationRetell?.name}
        </span>
        <IconButton style={{ position: "absolute", top: "2px", right: "4px" }}>
          <AiOutlineMore style={{ color: "white" }} />
        </IconButton>
        <Chip
          label={
            "On " +
            moment(state.selectedConversationRetell?.updatedAt).format(
              "Do MMM YYYY"
            )
          }
          style={{
            position: "absolute",
            bottom: "9px",
            right: "12px",
            border: "1px solid white",
            color: "white",
          }}
        />
      </div>
      <div className="bg-white px-8 pb-6">
        <div className="font-[Outfit] text-xl ml-28 mt-1 text-[#666]">
          Initiated a conversation with Grace
        </div>
      </div>
      {/* <div className="w-full grid grid-cols-2 gap-x-6 px-12">
                <div>
                    <div className="font-[Outfit] text-[18px] font-semibold mt-1 mb-[8px] text-[black]">
                        Call Details
                    </div>
                    <div
                        className="border-black border-[1px] rounded-lg px-3 py-[12px]"
                        style={{
                            boxShadow: "0 0 1px black",
                        }}
                    >

                        <DetailValue
                            label="Call Duration"
                            value={"11:53:04 - 11:59:34"}
                            noCopy
                        />

                        <DetailValue
                            label="Timestamp"
                            value={moment(finalTime).format("Do MMM YYYY")}
                            noCopy
                        />
                    </div>
                </div>
            </div> */}

      <div className="px-12 mt-8 pb-8 ">
        {/* <div className="font-[Outfit] font-semibold text-black text-xl">
          Call Transcript
        </div> */}
        {/* <p>{data}</p> */}

        {/* <AiSummary
          onGenerateAiSummaryButtonClick={onGenerateAiSummaryButtonClick}
        /> */}
        <Transcript />
      </div>
    </div>
  );
};

const DetailValue = ({ value, label, noCopy = false }) => {
  const [copyText, setCopyText] = React.useState("Copy");
  const [showCopy, setShowCopy] = React.useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText("Copy");
    }, 1000);
  };

  return (
    <div
      className="flex justify-between items-center -mt-[5px]"
      onMouseEnter={() => setShowCopy(true)}
      onMouseLeave={() => setShowCopy(false)}
    >
      <div className="font-[Outfit] text-md text-[#555]">{label}</div>
      <div className="font-[Outfit] text-md text-black flex items-center h-[32px]">
        {showCopy && !noCopy && (
          <Tooltip title={copyText}>
            <IconButton onClick={() => copyToClipboard(value)}>
              <BiCopy style={{ fontSize: "16px" }} />
            </IconButton>
          </Tooltip>
        )}
        {value}
      </div>
    </div>
  );
};

const PlaceHolder = () => {
  return (
    <div className="flex flex-col items-center  h-screen">
      <img
        className="w-[41%] mt-24"
        src="/insight.svg"
        alt="Choose conversation"
      />
      <h3
        className=" text-3xl font-semibold text-gray-900 mt-6"
        style={{ fontFamily: "Nunito Sans, sans-serif" }}
      >
        Choose a conversation to get started
      </h3>
      <span
        className="text-lg text-gray-500"
        style={{ fontFamily: "Nunito Sans, sans-serif" }}
      >
        Get useful Insights and Summaries with The Power of AI
      </span>
    </div>
  );
};

export default Analysis;
