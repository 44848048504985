import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { GlobalProvider } from "./contexts/GlobalContext";

const Theme = createTheme({
    typography: {
        fontFamily: "Outfit",
    },
});

ReactDOM.render(
    <React.StrictMode>
        <GlobalProvider>
            <Router>
                <ThemeProvider theme={Theme}>
                    <App />
                </ThemeProvider>
            </Router>
        </GlobalProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
