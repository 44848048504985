import { useGlobalContext } from "../../contexts/GlobalContext";
import styled from "@emotion/styled";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { keyframes } from "@mui/material";
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { MdContentCopy } from "react-icons/md";
import axios from "axios";

const Conversation = ({ onGenerateAiSummaryButtonClick }) => {
  const [transcript, setTranscript] = useState();
  useEffect(() => {
    const conversationDetail = async () => {
      console.log("running mentor query");
      const res = await axios.get(
        `https://ok1qlahe4k.execute-api.ap-south-1.amazonaws.com/webcall/get`
      );
      await setTranscript(res.data[0]);
      return res;
    };
    conversationDetail()
}, []);
  const [state] = useGlobalContext();
  return (
    <div className="mt-4">
      <ConversationBox className={ "border-black border-[1px] rounded-lg"}>
                {
                    (state.selectedConversation.messages || []).map((details, id) => {
                        if (details.sender !== "info") {
                            return (
                                <div key={id} className="max-w-[95%] mt-2 px-[12px]">
                                    <span className="font-semibold mr-2 ">{details.sender + ":"}</span>
                                    <span className="break-word">{details.message}</span>
                                </div>
                            );
                        }
                        return null;
                    })
                }
            </ConversationBox>
      {state.mail && !state.summaryLoading && (
        <TypeWritten text={state?.mail} />
      )}
      {!state.mail && (
        <div className="w-full flex flex-col mt-8 items-center">
          <GenerateButton onClick={onGenerateAiSummaryButtonClick} />
          <div className="font-[Outfit] font-medium w-7/12 leading-5 mt-6 text-center text-[#777] text-[16px]">
            Generate a personalised email to the candidate based on their call
            and engagement metrics
          </div>
        </div>
      )}
      {/* {transcript.name} */}
    </div>
  );
};

const GenerateButton = ({ onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <ButtonSkeletal
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <FaWandMagicSparkles
        style={{ color: hover ? "white" : "#fe2170" }}
        className=""
      />
      Generate Mail
    </ButtonSkeletal>
  );
};

const TypeWritten = ({ text }) => {
  const [displayText, setDisplayText] = useState("");
  const [currentWord, setCurrentWord] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentWord === text.split(" ").length) {
        clearInterval(interval);
      } else {
        if (currentWord % 7 === 0 && text.split(" ").length - currentWord > 7) {
          let newAppend = " ";
          for (let i = 0; i < 3; i++) {
            newAppend = newAppend + " " + text.split(" ")[currentWord + i];
          }
          setDisplayText((prevDisplayText) => prevDisplayText + newAppend);
          setCurrentWord((prevIndex) => prevIndex + 3);
        } else {
          setDisplayText(
            (prevDisplayText) =>
              prevDisplayText + " " + text.split(" ")[currentWord]
          );
          setCurrentWord((prevIndex) => prevIndex + 1);
        }
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [currentWord, text]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <div className="font-[Outfit] mt-4 border-2 bg-gray-100 p-4 rounded-md">
        {displayText}
      </div>
      {currentWord === text.split(" ").length && (
        <Button
          startIcon={<MdContentCopy />}
          style={{ marginTop: "12px" }}
          onClick={copyToClipboard}
        >
          Copy
        </Button>
      )}
    </>
  );
};

const linearGradientKeyframes = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }`;

const ButtonSkeletal = styled(Button)`
  text-transform: none !important;
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  display: flex;
  gap: 12px;
  border: 2px solid #fe2170aa !important;
  border-radius: 12px !important;
  padding: 4px 18px !important;
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
  animation: ${linearGradientKeyframes} 1s ease infinite;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  width: 200px;

  &:hover {
    background: #f32170;
    color: white;
    -webkit-text-fill-color: white;
  }
`;

const ConversationBox = styled.div`
    border-radius: 12px;
    user-select: none;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f4f4f4;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #999;
    }

    max-height: 200px;
    overflow: auto;
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100;
`;

export default Conversation;
