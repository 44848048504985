import React, {useState} from 'react';
import axios from 'axios';

const ExportToCSV = ({ data }) => {

  const [isExporting, setIsExporting] = useState(false);
  
  const fetchTranscriptSummary = async (callId) => {
    const apiUrl = `https://api.retellai.com/get-call/${callId}`;
    const bearerToken = process.env.REACT_APP_BEARER_TOKEN;
    
    try {
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${bearerToken}` },
      });
      return response.data.call_analysis.call_summary; // Assuming `summary` is the variable returned by the API
    } catch (error) {
      console.error("Error fetching summary for callId:", callId, error);
      return "Error fetching data";
    }
  };

  const handleExport = async () => {
    // Fetch summaries for all callIds in the data array
    setIsExporting(true); 
    const updatedData = await Promise.all(
      data.map(async (item) => {
        const summary = await fetchTranscriptSummary(item.callId);
        return {
          ...item,
          summary,
        };
      })
    );
    const escapeCSV = (text) => {
      if (typeof text === 'string' && /[",\n]/.test(text)) {
        // Escape double quotes and wrap in quotes
        return `"${text.replace(/"/g, '""')}"`;
      }
      return text;
    };

    // Prepare CSV data
    const csvRows = [
      ["ID", "First Name", "Last Name",  "Email", "Country",  "Program", "Application Year", "Summary"], // CSV headers
      ...updatedData.map(({ _id, firstname,lastname, email, country, program, applicationyear, summary }) => [
        escapeCSV(_id),
        escapeCSV(firstname),
        escapeCSV(lastname),
        escapeCSV(email),
        escapeCSV(country),
        escapeCSV(program),
        escapeCSV(applicationyear),
        escapeCSV(summary),
      ]),
    ];

    // Convert rows to CSV format
    const csvContent = csvRows.map(row => row.join(",")).join("\n");

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link and trigger download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "graceUserConversationsData.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsExporting(false);
  };

  return (
    <button style={{
      textTransform: "none",
      fontFamily: "Avenir, Noto sans",
      fontWeight: 500,
      backgroundColor: "#10CD45",
      borderRadius: "9px",
      fontSize: "1vw",
      color: "#FFFFFF",
      boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)",
      height: "45px",
      marginLeft: "1rem",
      width: "130px",
    }} onClick={handleExport} disabled={isExporting}>
      {isExporting ? "Exporting..." : "Export to CSV"}
    </button>
  );
};

export default ExportToCSV;
