import { useGlobalContext } from "../../contexts/GlobalContext";
import Dashboard from "./DashboardElements"
const WelcomeText = () => {
    const [state] = useGlobalContext();
    
 
    return ( 
        <>
            <Dashboard.WelcomeText>
                Welcome, {state.user?.name}
            </Dashboard.WelcomeText>
        </>
     );
}
 
export default WelcomeText;