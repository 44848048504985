import React from "react";
import PreviewCardWidget, { PreviewCardSite } from "./PreviewCard";

const Preview = ({ mentor }) => {
    return (
        <div className="ml-6">
            <h1 className="font-[Outfit] text-3xl">Preview</h1>
            <div className="flex flex-col items-center">
                <PreviewCardSite mentor={mentor} />
                <PreviewCardWidget mentor={mentor} />
            </div>
        </div>
    );
};

export default Preview;
