import axios from "axios";
import { BASE_URL } from "./url";

const mApi = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

export const getGroups = async () => {
    try {
        const result = await mApi.get("/groups");
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getGroup = async (id) => {
    try {
        const result = await mApi.get(`/groups/${id}`);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const addGroup = async (group) => {
    try {
        const result = await mApi.post("/groups", group);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};
