import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga";
import { Typography, Grid, Stack } from "@mui/material";
import SessionInfoContext from "../contexts/SessionsContext";

const TosPage = (params) => {
  const sessionId = useParams()["id"];
  const isMobile = useContext(SessionInfoContext);

  ReactGA.initialize("UA-208900493-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Grid container paddingBottom={7}>
      <Grid xs={1}></Grid>
      <Grid xs={10}>
        <Stack spacing={2}>
          <Typography
            sx={{ fontSize: 45, fontWeight: "bold" }}
            variant="h1"
            mt={6}
          >
            Terms of Service
          </Typography>
          <Typography variant="body1">
            Welcome to CollegeIt! <br />
            <br />
            Set out below are the terms and conditions (the “Terms”) that are
            applicable when you visit, or use any service provided at,
            www.collegeit.org (the “Site”). By choosing to visit, or use any
            service at, the Site, you confirm your acceptance of the Terms, and
            agree to comply with them.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            What are the other terms that apply to you?
          </Typography>
          <Typography variant="body1">
            In addition to the Terms, you are also governed by our{" "}
            <a href="/privacy">Privacy Policy</a>, which outlines how we collect
            and use your device information and personal information.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            The Terms may be amended
          </Typography>
          <Typography variant="body1">
            The Terms are subject to amendment without any prior notice to the
            users of the Site. You are advised to check the terms whenever you
            visit the Site in order to ensure that you are aware of the Terms
            applicable at the relevant time.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            No commercial use
          </Typography>
          <Typography variant="body1">
            You are not permitted to use any content or information on the Site
            for commercial purposes without prior permission from CollegeIt.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            Interacting with people on the Site
          </Typography>
          <Typography variant="body1">
            You must not indulge in any abusive, hateful, and offensive
            behaviour while engaging in interactions with speakers in live Q&A
            sessions hosted by CollegeIt, and chat mentors on CollegeIt Connect
            (together, the “Third Parties”). If you indulge in any such
            behaviour with the Third Parties, CollegeIt reserves the right to
            ban you from using any services on the Site.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            Conduct your own research and do not rely on the information on the
            Site
          </Typography>
          <Typography variant="body1">
            The information available on the Site is for general informational
            purposes only. CollegeIt is not responsible for verifying the
            authenticity, accuracy, and reliability of the information provided
            on the Site.
            <br />
            The information provided by Third Parties on the Site (“Third Party
            Information”) does not represent the views of CollegeIt. Third Party
            Information represents the personal views of the Third Parties.
            <br />
            Specifically, the chat mentors on CollegeIt Connect are not
            representatives of CollegeIt or any educational institution, and
            their views and opinions should not be construed as advice. <br />
            You are advised to conduct your own research and diligence to form
            independent views about universities and their programs, and before
            shortlisting, selecting, and applying for any higher education
            program.
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }} variant="h5">
            We are not responsible for any loss suffered by you
          </Typography>
          <Typography variant="body1">
            CollegeIt and the Third Parties are not liable to you for any loss
            or damage suffered by you as a result of using the services and/or
            relying on the information provided on the Site.
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={1}></Grid>
    </Grid>
  );
};

export default TosPage;
