import axios from "axios";
import { BASE_URL } from "./url";

const loginAxios = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

export const loginQuery = async (form) => {
    try {
        const result = await loginAxios.post(`/user/login`, form);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getUserQuery = async () => {
    try {
        const result = await loginAxios.get(`/user`);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const logoutQuery = async () => {
    try {
        const result = await loginAxios.get(`/user/logout`);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const register = async (form) => {
    try {
        const result = await loginAxios.post(`/user/register`, form);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const initialPasswordAsDefaultQuery = async () => {
    try {
        const result = await loginAxios.get("/user/use-default-password");
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const setInitialPasswordQuery = async (form = { password: "" }) => {
    try {
        if (!form || !form.password || form.password === "") {
            throw new Error({
                ok: false,
                response: {
                    data: {
                        message: "Password cannot be blank",
                    },
                },
            });
        }
        const result = await loginAxios.post(
            "/user/initial-password-reset",
            form
        );
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const initiateForgotPasswordQuery = async (email) => {
    try {
        const result = await loginAxios.post("/user/forgot-password", {
            email,
        });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const verifyForgotPasswordQuery = async (email, code) => {
    try {
        const result = await loginAxios.post("/user/forgot-password/verify", {
            email,
            code,
        });
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const changePasswordQuery = async (form) => {
    try {
        const result = await loginAxios.post(
            "/user/forgot-password/change",
            form
        );
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};
