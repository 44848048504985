import GetAmbassadorData from "./GetAmbassadorData";
import AmbassadorBox from "./AmbassadorBox";
import { useGlobalContext } from "../../contexts/GlobalContext";
import DashboardElements from "./DashboardElements";

const AmbassadorContainer = () => {
  // const arr = GetAmbassadorData();
  const [state] = useGlobalContext();
  const dummyConversion = [85, 55, 45, 33, 28, 12]
  if(state.user?.privilege === "Magnolia") {
    return (<DashboardElements.StyledAmbassadorContainer style={{ overflowY: "auto" }}>
    {
     state.mentors && state.mentors
      .map((details, id) => {
        return (
          <AmbassadorBox
            name={details.name || "Ambassador Name"}
            url={details.img_url_web || "/Default_pfp.jpg"}
            percentage={dummyConversion[id]}
            key={id}
          />
        );
      })
    }
    </DashboardElements.StyledAmbassadorContainer>)
  }

  return (
    <DashboardElements.StyledAmbassadorContainer style={{ overflowY: "auto" }}>
      {/* <div > */}
      {state.allMentorsPerformance &&
        state.allMentorsPerformance
          .slice() // Make a copy of the conversations array

          .sort((a, b) => b.conversations.length - a.conversations.length) // Sort conversations by repliedPercentageNew in decreasing order

          .map((details, id) => {
            return (
              <AmbassadorBox
                name={details.mentorName || "Ambassador Name"}
                url={details.img_url_web || "/Default_pfp.jpg"}
                percentage={details.conversations.length}
                key={id}
              />
            );
          })}
      {/* </div> */}
    </DashboardElements.StyledAmbassadorContainer>
  );
};

export default AmbassadorContainer;
