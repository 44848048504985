import DashboardElements from "./DashboardElements"; 

const StatsBox = (props) => {
    return (
        <DashboardElements.StatsBox>
            <DashboardElements.StatsBoxTitle>
                {props.title}
            </DashboardElements.StatsBoxTitle>
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <DashboardElements.StatsBoxCount>
                {props.count}
            </DashboardElements.StatsBoxCount>
            </div>
        </DashboardElements.StatsBox>
    )

}

export default StatsBox;