import { IconButton, Snackbar } from "@mui/material";
import { createContext, useContext, useReducer } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { getUserQuery, logoutQuery } from "../queries/login";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../components/Loading";

const initialState = {
    // auth
    user: null,

    snackbar: {
        open: false,
        message: "",
        severity: "success",
    },

    // overview
    filteredUniversity: "",

    // insights
    conversations: null,
    selectedConversation: null,
    selectedConversationRetell: null,
    selectedMentor: null,
    conversationLoading: false,
    mentors: null,
    mentorsLoading: false,
    summary: null,
    mail:null,
    summaryLoading: false,
    mailLoading:false,
    chats: [],
    chatsLoading: false,
    allMentorsPerformance: null,

    // groups
    groups: null,
    groupsLoading: true,
};

export const GActions = {
    SET_SNACKBAR: "SET_SNACKBAR",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
    SET_USER: "SET_USER",
    SET_FILTERED_UNIVERSITY: "SET_FILTERED_UNIVERSITY",
    SET_CONVERSATIONS: "SET_CONVERSATIONS",
    SET_CONVERSATIONS_RETELL: "SET_CONVERSATIONS_RETELL",
    SET_SELECTED_CONVERSATION: "SET_SELECTED_CONVERSATION",
    SET_SELECTED_CONVERSATION_RETELL: "SET_SELECTED_CONVERSATION_RETELL",
    SET_CONVERSATION_LOADING: "SET_CONVERSATION_LOADING",
    SET_ALL_MENTOR_PERFORMANCE: "SET_ALL_MENTOR_PERFORMANCE",
    SET_SELECTED_ALL_MENTOR_PERFORMANCE: "SET_ALL_MENTOR_PERFORMANCE_CONVERSATION",
    SET_ALL_MENTOR_PERFORMANCE_LOADING: "SET_ALL_MENTOR_PERFORMANCE_LOADING",
    SET_MENTORS: "SET_MENTORS",
    SET_MENTORS_LOADING: "SET_MENTORS_LOADING",
    SET_SUMMARY: "SET_SUMMARY",
    SET_SUMMARY_LOADING: "SET_SUMMARY_LOADING",
    SET_MAIL:"SET_MAIL",
    SET_MAIL_RETELL:"SET_MAIL_RETELL",
    SET_MAIL_LOADING:"SET_MAIL_LOADING",
    SET_CHATS: "SET_CHATS",
    SET_CHATS_LOADING: "SET_CHATS_LOADING",
    SET_SELECTED_MENTOR: "SET_SELECTED_MENTOR",
    SET_GROUPS: "SET_GROUPS",
    SET_GROUPS_LOADING: "SET_GROUPS_LOADING",
};

const reducer = (state, action) => {
    switch (action.type) {
        // auth
        case GActions.SET_USER:
            return { ...state, user: action.payload };
            
        case GActions.SET_ALL_MENTOR_PERFORMANCE:
            return { ...state, allMentorsPerformance: action.payload };
        // overview
        case GActions.SET_FILTERED_UNIVERSITY:
            return { ...state, filteredUniversity: action.payload };

        // insights
        case GActions.SET_CONVERSATIONS:
            return { ...state, conversations: action.payload };
        case GActions.SET_SELECTED_CONVERSATION:
            return { ...state, selectedConversation: action.payload };
        case GActions.SET_SELECTED_CONVERSATION_RETELL:
            return { ...state, selectedConversationRetell: action.payload };
        case GActions.SET_CONVERSATION_LOADING:
            return { ...state, conversationLoading: action.payload };
        case GActions.SET_MENTORS:
            return { ...state, mentors: action.payload };
        case GActions.SET_MENTORS_LOADING:
            return { ...state, mentorsLoading: action.payload };
        case GActions.SET_SUMMARY:
            return { ...state, summary: action.payload };
        case GActions.SET_SUMMARY_LOADING:
            return { ...state, summaryLoading: action.payload };
        case GActions.SET_MAIL:
            return { ...state, mail: action.payload };    
            case GActions.SET_MAIL_RETELL:
                return { ...state, mailRetell: action.payload };    
        case GActions.SET_MAIL_LOADING:
            return { ...state, mailLoading: action.payload };    
        case GActions.SET_CHATS:
            return { ...state, chats: action.payload };
        case GActions.SET_CHATS_LOADING:
            return { ...state, chatsLoading: action.payload };
        case GActions.SET_SELECTED_MENTOR:
            return { ...state, selectedMentor: action.payload };

        // groups
        case GActions.SET_GROUPS:
            return { ...state, groups: action.payload };
        case GActions.SET_GROUPS_LOADING:
            return { ...state, groupsLoading: action.payload };

        // snackbar
        case GActions.SET_SNACKBAR:
            action.payload.open = true;
            return { ...state, snackbar: action.payload };
        case GActions.CLOSE_SNACKBAR:
            return { ...state, snackbar: initialState.snackbar };
        default:
            return state;
    }
};

const GlobalContext = createContext([initialState, () => {}]);

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        user: JSON.parse(localStorage.getItem("user")) || null,
    });

    const [userLoading, setUserLoading] = useState(true);

    const checkTokenAndLogin = async () => {
        setUserLoading(true);
        try {
            const response = await getUserQuery();
            console.log(response);
            if (response.ok) {
                localStorage.setItem("user", JSON.stringify(response.user));
                dispatch({
                    type: GActions.SET_USER,
                    payload: response.user,
                });
                dispatch({
                    type: GActions.SET_FILTERED_UNIVERSITY,
                    payload: response.user.privilege,
                });
            } else {
                await logoutQuery();
                localStorage.removeItem("user");
                dispatch({
                    type: GActions.SET_USER,
                    payload: null,
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: GActions.SET_USER,
                payload: null,
            });
            localStorage.removeItem("user");
            await logoutQuery();
        } finally {
            setUserLoading(false);
        }
    };

    useEffect(() => {
        checkTokenAndLogin();
    }, []);

    if (userLoading)
        return (
            <Loading>
                <h1 className="text-white font-[Outfit]">Loading...</h1>
            </Loading>
        );

    return (
        <GlobalContext.Provider value={[state, dispatch]}>
            {children}
            <CustomSnackBar state={state} dispatch={dispatch} />
        </GlobalContext.Provider>
    );
};

const CustomSnackBar = ({ state, dispatch }) => {
    return (
        <Snackbar
            open={state.snackbar.open}
            autoHideDuration={5000}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            onClose={() =>
                dispatch({
                    type: GActions.CLOSE_SNACKBAR,
                })
            }
            message={state.snackbar.message}
            severity={state.snackbar.severity}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    onClick={() => dispatch({ type: GActions.CLOSE_SNACKBAR })}
                >
                    <CloseIcon fontSize="small" style={{ color: "white" }} />
                </IconButton>
            }
        />
    );
};

export default GlobalContext;

export const useGlobalContext = () => {
    const [state, dispatch] = useContext(GlobalContext);

    return [state, dispatch];
};
