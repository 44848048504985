import React, { useState, useEffect } from "react";

const AllLeadsAmbassador = (props) => {
  const [color, setColor] = useState("#00B69B");
  //   const [imgStyle, setImgStyle] = useState({ marginLeft: "0.5rem", marginTop: "0.3rem" });

  useEffect(() => {
    const newColor = chooseColor(props.responseRate);
    setColor(newColor);
  }, [props.responseRate]);

  const chooseColor = (percentage) => {
    if (percentage >= 10) {
      return "black";
    } else if (percentage > 5 && percentage <= 10) {
      return "black";
    } else {
      return "black";
    }
  };

  //   const open = () => {
  //     setImgStyle({ marginLeft: "0.5rem", marginTop: "0.3rem", transform: "rotate(90deg)" });
  //   };

  return (
    <div
      style={{
        background: "#F4F8FF",
        padding: "0.5rem 0rem 0.5rem 2rem",
        marginBottom: "5px",
      }}
    >
      <button
        onClick={props.open}
        style={{
          display: "flex",
          // alignItems: "center",
          // width:"80%"
        }}
      >
        <div
          style={{
            color: "#333",
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            display: "flex",
            justifyContent: "left",
            lineHeight: "normal",
            marginRight: "0.5rem",
            padding:"0"
          }}
        >
          {props.ambassadorName+ ` - `}
        </div>
        <span
          style={{
            color: "#00B69B",
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            marginRight: "0.5rem",
            // width:"20%"
          }}
        >
          {props.responseRate} leads{" "}
        </span>
        <img
          src="/AllLeadsAmbassadorButton.svg"
          alt="icon"
          style={props.imgStyle}
        />
      </button>
    </div>
  );
};

export default AllLeadsAmbassador;
