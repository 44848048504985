import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { useGlobalContext } from "../../contexts/GlobalContext";
import DashboardElements from "./DashboardElements";
import AllLeadsAmbassador from "./AllLeadsAmbassador";
import UniversityGrouping from "./UniversityGroping";

const AllLeadsTable = ({ selected, setSelected }) => {
  const [state] = useGlobalContext();
  const [selectedButton, setSelectedButton] = useState(null);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const generateTableHeaders = () => {
    const headers =
      selected !== "By ambassador"
        ? ["Name", "Email", "Cycle", "Mentor Name", "Last Message (Mentor)", "Last Message (Student)", "Created on"]
        : ["Name", "Email", "Cycle", "Last Message (Mentor)", "Last Message (Student)", "Created on"]
    return headers.map((title, id) => (
      <DashboardElements.Tableh key={id} style={{ flex: (title === "Email" || title === "Cycle") ? (title === "Email" ? "1.5" : "0.5") : "1" }}>{title}</DashboardElements.Tableh>
    ));
  };

  const renderConversationDetails = (conversation) => (
    <div key={conversation._id}>
      <DashboardElements.TableTr>
        <DashboardElements.Tabled>{conversation.name}</DashboardElements.Tabled>
        <DashboardElements.Tabled style={{ flex: "1.5" }}>{conversation.email}</DashboardElements.Tabled>
        {selected === "By ambassador" && (
          <DashboardElements.Tabled style={{ flex: "0.5" }}>{conversation.cycle}</DashboardElements.Tabled>
        )}
        {selected !== "By ambassador" && (
          <DashboardElements.Tabled style={{ flex: "0.5" }}>{conversation.year}</DashboardElements.Tabled>
        )}
        {selected !== "By ambassador" && (
          <DashboardElements.Tabled>{conversation.mentorName}</DashboardElements.Tabled>
        )}
        <DashboardElements.Tabled>
          {conversation.lastResponseMentor
            ? moment.utc(conversation.lastResponseMentor).local().format("Do MMM, YYYY")
            : "N/A"}
        </DashboardElements.Tabled>
        <DashboardElements.Tabled>
          {conversation.lastResponseStudent
            ? moment.utc(conversation.lastResponseStudent).local().format("Do MMM, YYYY")
            : "N/A"}
        </DashboardElements.Tabled>
        <DashboardElements.Tabled>
          {conversation.createdAt
            ? moment.utc(conversation.createdAt).local().format("Do MMM, YYYY")
            : "N/A"}
        </DashboardElements.Tabled>
      </DashboardElements.TableTr>
    </div>
  );

  const renderTableRows = (conversations) => {
    return conversations.map(renderConversationDetails);
  };

  return (
    <DashboardElements.AllLeadsTable>
      <div
        style={{
          display: "flex",
          padding: "16px 0 10px 0",
          color: "#333",
          fontFamily: "Nunito Sans, sans-serif",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          borderRadius: "12px 12px 0px 0px",
          background: "#E8EEFC",
          alignItems: "center",
        }}
      >
        {generateTableHeaders()}
      </div>

      {selected === "By ambassador" ? (
        state.allMentorsPerformance &&
        state.allMentorsPerformance
          .slice()
          .sort((a, b) => b.conversations.length - a.conversations.length)
          .map((details, id) => (
            <div key={details.mentorId}>
              <AllLeadsAmbassador
                responseRate={details.conversations.length}
                ambassadorName={details.mentorName}
                open={() =>
                  setSelectedButton((prevSelectedButton) =>
                    prevSelectedButton === id ? null : id
                  )
                }
                imgStyle={{
                  marginLeft: "0.5rem",
                  marginTop: "0.3rem",
                  transform:
                    selectedButton === id ? "rotate(90deg)" : "rotate(0deg)",
                }}
              />
              {selectedButton === id && (
                <div>{renderTableRows(details.conversations)}</div>
              )}
            </div>
          ))
      ) : selected === "By university" ? (
        state.conversations &&
        Object.values(
          state.conversations.reduce((groupedConversations, conversation) => {
            const key = conversation.university.toLowerCase();
            if (!groupedConversations[key]) {
              groupedConversations[key] = [];
            }
            groupedConversations[key].push(conversation);
            return groupedConversations;
          }, {})
        ).map((universityConversations, index) => (
          <div key={index}>
            <UniversityGrouping
              universityName={capitalizeFirstLetter(
                universityConversations[0].university
              )}
              open={() =>
                setSelectedButton((prevSelectedButton) =>
                  prevSelectedButton === index ? null : index
                )}
              imgStyle={{
                marginLeft: "0.5rem",
                marginTop: "0.3rem",
                transform:
                  selectedButton === index ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
            <div>
              {selectedButton === index &&
                universityConversations
                  .slice()
                  .sort(
                    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
                  )
                  .map(renderConversationDetails)}
            </div>
          </div>
        ))
      ) : (
        state.conversations &&
        state.conversations
          .slice()
          .sort((a, b) => {
            if (selected === "Name") return a.name.localeCompare(b.name);
            if (selected === "Email") return a.email.localeCompare(b.email);
            if (selected === "Time") return new Date(b.createdAt) - new Date(a.createdAt);
            if (selected === "Cycle") return a.year.localeCompare(b.year);
            return 0;
          })
          .map(renderConversationDetails)
      )}
    </DashboardElements.AllLeadsTable>
  );

};

export default AllLeadsTable;
