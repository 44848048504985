import Insights from "../components/Insights";

const InsightsPage = () => {
    return (
        <>
            <Insights />
        </>
    );
};

export default InsightsPage;
