import { TextField } from "@mui/material";
import { useState } from "react";
import { ButtonLoading } from "./ActualLoginForm";
import { LoginForm, LoginTitle, SubmitButton } from "./items";

const FirstTimePasswordSet = ({
  onSubmit = async () => {},
  onUseDefaultPassword = async () => {},
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitting, setSubmitting] = useState("");
  const [error, setError] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const arePasswordsValid = () => {
    setError({ newPassword: "", confirmPassword: "" });
    let isPasswordValid = true;

    if (newPassword.includes(" ")) {
      setError((prev) => ({
        ...prev,
        newPassword: "Password cannot contain white spaces",
      }));
      isPasswordValid = false;
    }

    if (newPassword.length < 6) {
      setError((prev) => ({
        ...prev,
        newPassword: "Password should contain atleast 6 characters",
      }));
      isPasswordValid = false;
    }

    if (confirmPassword !== newPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Password does not match",
      }));
      isPasswordValid = false;
    }
    return isPasswordValid;
  };

  const handlePasswordChangeFormSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    if (arePasswordsValid()) {
      await onSubmit(newPassword);
    }
    setSubmitting(false);
  };

  return (
    <LoginForm onSubmit={handlePasswordChangeFormSubmit}>
      <LoginTitle>Change Password</LoginTitle>
      <TextField
        fullWidth
        margin="dense"
        label="New Password"
        color="secondary"
        type="password"
        variant="standard"
        error={error.newPassword !== ""}
        helperText={error.newPassword}
        value={newPassword}
        onChange={handleNewPasswordChange}
        sx={{ fontFamily: "Outfit" }}
        required
      />
      <TextField
        fullWidth
        margin="dense"
        label="Confirm Password"
        color="secondary"
        type="password"
        variant="standard"
        error={error.confirmPassword !== ""}
        helperText={error.confirmPassword}
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        sx={{ fontFamily: "Outfit" }}
        required
      />

      {submitting ? (
        <ButtonLoading color={"#168c00"} marginTop="0rem" />
      ) : (
        <SubmitButton
          type="submit"
          disabled={submitting}
          style={{
            background:
              "linear-gradient(175.93deg, #21c9c0 -10.23%, #168c00 117.03%)",
            marginTop: "1.5rem",
          }}
        >
          Change Password
        </SubmitButton>
      )}

      <SubmitButton
        style={{
          marginTop: "1rem",
          background:
            "linear-gradient(175.93deg, #ff5163 -10.23%, #781e39 117.03%)",
        }}
        onClick={onUseDefaultPassword}
      >
        Use Default
      </SubmitButton>
    </LoginForm>
  );
};

export default FirstTimePasswordSet;
