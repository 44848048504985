import CallOverview from "../components/CallOverview";

const CallOverviewPage = () => {
    return (
        <>
            <CallOverview />
        </>
    );
};

export default CallOverviewPage;
