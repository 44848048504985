import axios from "axios";
import { IMG_UPLOAD_URL } from "./url";

const imgApi = axios.create({
    baseURL: IMG_UPLOAD_URL,
    headers: {
        Authorization: "Bearer " + process.env.REACT_APP_IMG_AUTH,
    },
});

export const uploadImage = async (file, cb = () => {}) => {
    try {
        const formData = new FormData();
        formData.append("image", file);
        const res = await imgApi.post("/", formData, {
            onUploadProgress: cb,
        });
        return res.data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const deleteImage = async (url) => {
    try {
        const body = {
            url,
        };
        console.log(body);
        const res = await imgApi.post("/delete", body);
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        return error;
    }
};
