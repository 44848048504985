import React from "react";
import { Container, Header, Heading } from "../Overview/OverviewElements";
import { BackButton } from "../Mentors/NewMentor";
import { Button } from "@mui/material";
import { addGroup } from "../../queries/groups";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";

const NewGroup = () => {
    const [submitting, setSubmitting] = React.useState(false);
    const [values, setValues] = React.useState({
        name: "",
        university: "",
        email: "",
    });
    const [state, dispatch] = useGlobalContext();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(values);
        setSubmitting(true);
        try {
            const res = await addGroup(values);
            const newGroup = res?.group;
            if (newGroup) {
                const old = [...state.groups, newGroup];
                dispatch({
                    type: GActions.SET_GROUPS,
                    payload: old,
                });
            }
            dispatch({
                type: GActions.SET_SNACKBAR,
                payload: {
                    message: res.message,
                    severity: "success",
                },
            });
            setValues({
                name: "",
                university: "",
                email: "",
            });
        } catch (error) {
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Container>
            <Header>
                <Heading>
                    <BackButton link="/manage" />
                    New Group
                </Heading>
            </Header>
            <form className="w-[700px]" onSubmit={handleSubmit}>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                        <label
                            htmlFor="name"
                            className="font-[Outfit] block text-lg font-medium leading-6 text-gray-900"
                        >
                            University Code (eg. boston)
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="name"
                                required
                                autoComplete="given-name"
                                className="block px-2 font-[Outfit] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                value={values.name}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label
                            htmlFor="university"
                            className="font-[Outfit] block text-lg font-medium leading-6 text-gray-900"
                        >
                            University Name (eg. Boston University)
                        </label>
                        <div className="mt-2">
                            <input
                                name="university"
                                required
                                type="text"
                                autoComplete="university"
                                className="px-2 font-[Outfit] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                value={values.university}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <label
                            htmlFor="email"
                            className="font-[Outfit] block text-lg font-medium leading-6 text-gray-900"
                        >
                            Email
                        </label>
                        <div className="mt-2">
                            <input
                                name="email"
                                required
                                type="email"
                                autoComplete="off"
                                className="px-2 font-[Outfit] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                value={values.email}
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-6 mt-8">
                    <Button
                        disabled={submitting}
                        type="submit"
                        variant="contained"
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </Container>
    );
};

export default NewGroup;
