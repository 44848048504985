import DashboardElements from "./DashboardElements";
import AmbassadorContainer from "./AmbassadorContainer";
import SeeAllAmbassadors from "./SeeAllAmbassadors";
import ChartContainer from "./ChartContainer"
import BarGraphContainer from "./BarGraphContainer";
const MiddleContainer = () => {
  return (
    <DashboardElements.MiddleContainer>
      {/* <ChartContainer/> */}
    <BarGraphContainer/>
    
      <DashboardElements.TopAmbassadorsContainer>
        <DashboardElements.AmbassadorsHead>
          Ambassador engagement
        </DashboardElements.AmbassadorsHead>
        <DashboardElements.AmbassadorsDes>
          Number of leads per ambassador
        </DashboardElements.AmbassadorsDes>
        <AmbassadorContainer />

        {/* <SeeAllAmbassadors /> */}
      </DashboardElements.TopAmbassadorsContainer>
    </DashboardElements.MiddleContainer>
  );
};

export default MiddleContainer;
