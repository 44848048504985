import { useState } from "react";
import { TextField } from "@mui/material";
import {
  ForgotLink,
  LoginForm,
  LoginSubtitle,
  LoginTitle,
  SubmitButton,
} from "./items";
import { ButtonLoading } from "./ActualLoginForm";

const VerifyResetCode = ({ onSubmit = async () => {} }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    code: "",
  });

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    await onSubmit(code, setError, setLoading);
  };

  return (
    <LoginForm onSubmit={handleFormSubmit}>
      <LoginTitle>Reset Password</LoginTitle>
      <LoginSubtitle>
        A 6 digit verification code has been successfully sent. Please enter the
        code below.
      </LoginSubtitle>
      <TextField
        fullWidth
        margin="dense"
        label="Reset Code"
        color="secondary"
        type="number"
        variant="standard"
        error={error.code !== ""}
        helperText={error.code}
        value={code}
        onChange={handleCodeChange}
        sx={{ fontFamily: "Outfit" }}
        required
      />
      {loading ? (
        <ButtonLoading />
      ) : (
        <SubmitButton
          type="submit"
          disabled={loading}
          style={{ marginTop: "1.5rem" }}
        >
          Submit
        </SubmitButton>
      )}
    </LoginForm>
  );
};

export default VerifyResetCode;
