import React from "react";
import { Avatar, Chip, IconButton, Tooltip } from "@mui/material";
import { AiOutlineMore } from "react-icons/ai";
import moment from "moment";
import { BiCopy } from "react-icons/bi";
import styled from "@emotion/styled";
import AiSummary from "./AiSummary";
import { useGlobalContext } from "../../contexts/GlobalContext";

const Analysis = ({ onGenerateAiSummaryButtonClick }) => {
    const [state] = useGlobalContext();

    if (!state.selectedConversation) return <PlaceHolder />;
    const responseStudent = state.selectedConversation.lastResponseStudent;
    const responseMentor = state.selectedConversation.lastResponseMentor;
    const lastResponseDateStudent = new Date(responseStudent);
    const lastResponseDateMentor = new Date(responseMentor);
    const createdAtConv = state.selectedConversation.createdAt;
    const createdDate = new Date(createdAtConv);
    let finalTime;
    if (
        lastResponseDateMentor !== undefined &&
        lastResponseDateStudent !== undefined
    ) {
        if (lastResponseDateMentor > lastResponseDateStudent)
            finalTime = lastResponseDateMentor;
        else finalTime = lastResponseDateStudent;
    } else if (
        lastResponseDateMentor === undefined &&
        lastResponseDateStudent !== undefined
    ) {
        finalTime = lastResponseDateStudent;
    } else if (
        lastResponseDateStudent === undefined &&
        lastResponseDateMentor !== undefined
    ) {
        finalTime = lastResponseDateMentor;
    } else {
        finalTime = "final";
    }
    return (
        <div className="w-full bg-white shadow-lg">
            <div className="w-full bg-[#5f00d8] px-8 py-2 relative flex">
                <Avatar
                    style={{
                        height: "100px",
                        width: "100px",
                        fontSize: "65px",
                        transform: "translateY(50%)",
                        backgroundColor: "#7C3AED",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.44)",
                    }}
                >
                    {state.selectedConversation?.name[0]}
                </Avatar>
                <span className="font-[Outfit] text-4xl font-semibold mt-auto ml-6 text-white">
                    {state.selectedConversation?.name}
                </span>
                <IconButton
                    style={{ position: "absolute", top: "2px", right: "4px" }}
                >
                    <AiOutlineMore style={{ color: "white" }} />
                </IconButton>
                <Chip
                    label={
                        "On " +
                        moment(state.selectedConversation?.createdAt).format(
                            "Do MMM YYYY"
                        )
                    }
                    style={{
                        position: "absolute",
                        bottom: "9px",
                        right: "12px",
                        border: "1px solid white",
                        color: "white",
                    }}
                />
            </div>
            <div className="bg-white px-8 pb-6">
                <div className="font-[Outfit] text-xl ml-28 mt-1 text-[#666]">
                    Initiated a conversation with {state.selectedMentor?.name}
                </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-x-6 px-12">
                <div>
                    <div className="font-[Outfit] text-[18px] font-semibold mt-1 mb-[8px] text-[black]">
                        Details
                    </div>
                    <div
                        className="border-black border-[1px] rounded-lg px-3 py-[12px]"
                        style={{
                            boxShadow: "0 0 1px black",
                        }}
                    >
                        <DetailValue
                            label={"Email"}
                            value={state.selectedConversation?.email}
                        />

                        <DetailValue
                            label={"Phone Number"}
                            value={state.selectedConversation?.phone}
                        />

                        <DetailValue
                            label="Initiated on"
                            value={moment(createdDate).format("Do MMM YYYY")}
                            noCopy
                        />

                        <DetailValue
                            label="Last message on"
                            value={moment(finalTime).format("Do MMM YYYY")}
                            noCopy
                        />
                    </div>
                </div>
                <div>
                    <div className="font-[Outfit] text-[18px] font-semibold mt-1 mb-2 text-[black]">
                        Mentor Details
                    </div>
                    <MentorCardShowOnly mentor={state?.selectedMentor} />
                </div>
            </div>

            <div className="px-12 mt-8 pb-8">
                <div className="font-[Outfit] font-semibold text-black text-xl">
                    Conversation Summary
                </div>
                <AiSummary
                    onGenerateAiSummaryButtonClick={
                        onGenerateAiSummaryButtonClick
                    }
                />
            </div>
        </div>
    );
};

const DetailValue = ({ value, label, noCopy = false }) => {
    const [copyText, setCopyText] = React.useState("Copy");
    const [showCopy, setShowCopy] = React.useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopyText("Copied!");
        setTimeout(() => {
            setCopyText("Copy");
        }, 1000);
    };

    return (
        <div
            className="flex justify-between items-center -mt-[5px]"
            onMouseEnter={() => setShowCopy(true)}
            onMouseLeave={() => setShowCopy(false)}
        >
            <div className="font-[Outfit] text-md text-[#555]">{label}</div>
            <div className="font-[Outfit] text-md text-black flex items-center h-[32px]">
                {showCopy && !noCopy && (
                    <Tooltip title={copyText}>
                        <IconButton onClick={() => copyToClipboard(value)}>
                            <BiCopy style={{ fontSize: "16px" }} />
                        </IconButton>
                    </Tooltip>
                )}
                {value}
            </div>
        </div>
    );
};

const MentorCardShowOnly = ({ mentor }) => {
    const [copyText, setCopyText] = React.useState("Copy");
    const [showCopy, setShowCopy] = React.useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopyText("Copied!");
        setTimeout(() => {
            setCopyText("Copy");
        }, 1000);
    };

    return (
        <Cover
            onMouseEnter={() => setShowCopy(true)}
            onMouseLeave={() => setShowCopy(false)}
            style={{
                border: "1px solid gray",
            }}
        >
            <ProfilePic src={mentor?.img_url_web || "/avatar.webp"} alt="img" />
            <Details>
                <Name>{mentor?.name}</Name>
                <Specialization>{mentor?.specialization}</Specialization>

                <Specialization>
                    {mentor?.phone}
                    {showCopy && (
                        <Tooltip title={copyText}>
                            <IconButton onClick={() => copyToClipboard()}>
                                <BiCopy style={{ fontSize: "12px" }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Specialization>

                <Specialization style={{ color: "black", marginTop: "8px" }}>
                    {mentor?.university?.length > 30
                        ? mentor?.university?.slice(0, 27) + "..."
                        : mentor?.university}
                </Specialization>
            </Details>
        </Cover>
    );
};

const PlaceHolder = () => {
    return (
        <div className="flex flex-col items-center  h-screen">
            <img
                className="w-[41%] mt-24"
                src="/insight.svg"
                alt="Choose conversation"
            />
            <h3 className=" text-3xl font-semibold text-gray-900 mt-6" style={{fontFamily: 'Nunito Sans, sans-serif',}}>
                Choose a conversation to get started
            </h3>
            <span className="text-lg text-gray-500" style={{fontFamily: 'Nunito Sans, sans-serif',}}>
                Get useful Insights and Summaries with The Power of AI
            </span>
        </div>
    );
};

const Cover = styled.div`
    background: white;
    box-shadow: 0 0 1px black;
    padding: 12px 18px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    transition: 100ms all ease-out;
    border-radius: 12px;
    user-select: none;
`;

const ProfilePic = styled.img`
    width: 90px;
    height: 90px;
    border-radius: 50%;
`;

const Details = styled.div`
    display: flex;
    margin-left: 6px;
    flex-direction: column;
    padding: 4px 0;
    gap: 3px;
`;

const Name = styled.span`
    font-size: 18px;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
`;

const Specialization = styled.span`
    font-size: 14px;
    line-height: 1;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    color: #7a7a7a;
`;

export default Analysis;
