import DashboardElements from "./DashboardElements";
import { useState, useEffect } from "react";

const AmbassadorBox = (props) => {
  const imgStyle = {
    flexShrink: 0,
    borderRadius: "36px",
    marginRight: "0.75rem",
    width: "36px",
    height: "36px",
  };

  if (window.innerWidth <= 1700 && window.innerWidth > 1450) {
    imgStyle.width = "36px";
    imgStyle.height = "36px";
  } else if (window.innerWidth <= 1450) {
    imgStyle.width = "26px";
    imgStyle.height = "26px";
  }

  const [color, setColor] = useState("#00B69B");

  useEffect(() => {
    const newColor = chooseColor(props.percentage);
    setColor(newColor);
  }, [props.percentage]);

  const chooseColor = (percentage) => {
    if (percentage >= 10) {
      return "#4880FF";
    } else if (percentage > 5 && percentage <= 10) {
      return "#4880FF";
    } else {
      return "#4880FF";
    }
  };

  return (
    <DashboardElements.AmbassadorBox>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={props.url} style={imgStyle} alt="icon" />
        <DashboardElements.AmbassadorsName>
          {props.name}
        </DashboardElements.AmbassadorsName>
      </div>
      <DashboardElements.AmbassadorsPercentage
        style={{
          color: color,
          width: "14px",
          display: "flex",
          alignItems: "center",
          paddingRight: "30px"
        }}
      >
        {props.percentage}
      </DashboardElements.AmbassadorsPercentage>
    </DashboardElements.AmbassadorBox>
  );
};

export default AmbassadorBox;
