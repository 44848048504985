import { useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import {
    Container,
    ExportButton,
    Header,
    Heading,
} from "../components/Overview/OverviewElements";
import { Button, InputBase, Paper } from "@mui/material";
import { useEffect } from "react";
import {
    // deleteMeeting,
    getMeetings,
    // toggleMeetingStatus,
} from "../queries/meetings";
import { Loading } from "../components/Overview";
import MeetingRequestsTable from "../components/MeetingRequestsTable";
import { CSVLink } from "react-csv";
import { GActions, useGlobalContext } from "../contexts/GlobalContext";
import { getGroups } from "../queries/groups";

const MeetingRequests = () => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [records, setRecords] = useState([]);
    const [showResolved, setShowResolved] = useState(true);
    const [state, dispatch] = useGlobalContext();

    const [filteredRecords, setFilteredRecords] = useState([]);

    const csvLink = useRef();

    useEffect(() => {
        setLoading(true);
        getMeetings().then((res) => {
            setRecords(res?.meetings || []);
            console.log(res?.meetings);
            setLoading(false);
        });

        if (state.user?.privilege === "admin" && !state.groups) {
            dispatch({ type: GActions.SET_GROUPS_LOADING, payload: true });
            getGroups()
                .then((res) => {
                    dispatch({
                        type: GActions.SET_GROUPS,
                        payload: res.groups,
                    });
                })
                .finally(() => {
                    dispatch({
                        type: GActions.SET_GROUPS_LOADING,
                        payload: false,
                    });
                });
        }
    }, []);

    useEffect(() => {
        setFilteredRecords(records || []);
        if (showResolved) {
            setFilteredRecords(records);
        } else {
            const filtered = records.filter((record) => !record.resolved);
            setFilteredRecords(filtered);
        }
        setSearch("");
    }, [records]);

    useEffect(() => {
        if (showResolved) {
            setFilteredRecords(records);
        } else {
            const filtered = records.filter((record) => !record.resolved);
            setFilteredRecords(filtered);
        }
    }, [showResolved]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        const filtered = records.filter((record) => {
            return record.email.toLowerCase().includes(e.target.value);
        });
        setFilteredRecords(filtered);
    };

    // const toggleMeet = async (id) => {
    //     toggleMeetingStatus(id).then((res) => {
    //         if (!res.ok) return;

    //         const meet = records.find((record) => record._id === id);
    //         const copy = [...records];
    //         const index = copy.findIndex((record) => record._id === id);
    //         copy[index] = { ...copy[index], resolved: !meet.resolved };
    //         setRecords(copy);
    //     });
    // };

    // const deleteMeet = async (id) => {
    //     deleteMeeting(id).then((res) => {
    //         if (!res.ok) return;
    //         const copy = [...records];
    //         const index = copy.findIndex((record) => record._id === id);
    //         copy.splice(index, 1);
    //         setRecords(copy);
    //     });
    // };

    const downloadCsvHandler = () => {
        csvLink.current.link.click();
    };

    return (
        <Container>
            <Header>
                <Heading>Meeting Requests</Heading>
                <div style={{ display: "flex" }}>
                    {!loading && (
                        <>
                            <Paper
                                component={"form"}
                                style={{
                                    margin: "2px 16px",
                                    padding: "3px 20px 3px 4px",
                                    borderRadius: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    transition: "200ms ease-in-out",
                                }}
                                sx={{
                                    background: "#f2f2f2",
                                    "&:hover": {
                                        background: "#dcdcdc",
                                        boxShadow: "0 0 2px #555",
                                    },
                                    ":focus-within": {
                                        background: "#ebebeb",
                                        boxShadow: "0 0 2px #555",
                                    },
                                }}
                                elevation={0}
                            >
                                <BsSearch
                                    style={{
                                        color: "#7a7a7a",
                                        padding: "2px 6px",
                                        fontSize: "30px",
                                    }}
                                />
                                <InputBase
                                    placeholder="Search"
                                    style={{
                                        margin: "0",
                                        marginLeft: "4px",
                                        fontFamily: "Outfit",
                                    }}
                                    value={search}
                                    onChange={handleSearch}
                                />
                            </Paper>

                            <ExportButton onClick={downloadCsvHandler}>
                                Export to Excel
                            </ExportButton>
                            <CSVLink
                                data={filteredRecords}
                                ref={csvLink}
                                target="_blank"
                                filename="leads.csv"
                            />
                        </>
                    )}
                </div>
            </Header>
            {loading ? (
                <Loading />
            ) : (
                <MeetingRequestsTable
                    leads={filteredRecords}
                    // toggleMeet={toggleMeet}
                    // deleteMeet={deleteMeet}
                />
            )}

            {filteredRecords.length === 0 && !loading && (
                <div
                    style={{
                        fontFamily: "Outfit",
                        fontSize: "1.2rem",
                        color: "#7a7a7a",
                        textAlign: "center",
                        marginTop: "20px",
                    }}
                >
                    No records found
                </div>
            )}
        </Container>
    );
};

export default MeetingRequests;
