import React, { useEffect, useRef, useState } from "react";
import { UpperForm } from "./NewMentorElements";
import { BiUpload } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineUserAdd } from "react-icons/ai";
import { RiAccountCircleLine } from "react-icons/ri";
import { MdAlternateEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineLinkedin } from "react-icons/ai";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { deleteImage, uploadImage } from "../../queries/images";
import { getGroups } from "../../queries/groups";

const NewMentorForm = ({
    mentor,
    setMentor,
    update = false,
    onFormSubmit = () => {},
    submitting = false,
}) => {
    const [enlargeImg, setEnlargeImg] = React.useState(false);
    const [state, globalDispatch] = useGlobalContext();

    const uploadRef = useRef(null);

    useEffect(() => {
        if (!state.groups) {
            getGroups().then((res) => {
                globalDispatch({
                    type: GActions.SET_GROUPS,
                    payload: res.groups,
                });
            });
        }
    }, []);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const handleInputChange1 = (e) => {
        const { value, checked } = e.target;
        setMentor(prevState => {
          let newSpecializationArray = prevState.specialization ? prevState.specialization.split(', ') : [];
          if (checked) {
            newSpecializationArray.push(value);
          } else {
            newSpecializationArray = newSpecializationArray.filter(item => item !== value);
          }
          const newSpecialization = newSpecializationArray.join(', ');
          return { ...prevState, specialization: newSpecialization };
        });
      };
      const handleInputChange2 = (e) => {
        const { value, checked } = e.target;
        setMentor(prevState => {
          let newSpecializationArray = prevState.country ? prevState.country.split(', ') : [];
          if (checked) {
            newSpecializationArray.push(value);
          } else {
            newSpecializationArray = newSpecializationArray.filter(item => item !== value);
          }
          const newSpecialization = newSpecializationArray.join(', ');
          return { ...prevState, country: newSpecialization };
        });
      };
    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };
    const toggleDropdown1 = () => {
        setDropdownOpen1(!dropdownOpen1);
      };
    const handleInputChange = (field, value) => {
        setMentor((prev) => ({ ...mentor, ...prev, [field]: value }));
    };

    const onUploadButtonClick = () => {
        uploadRef.current.click();
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        console.log(file);
        try {
            if (file) {
                const onUploadProgress = (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percent = Math.floor((loaded * 100) / total);
                    console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                };

                const result = await uploadImage(file, onUploadProgress);

                console.log(result);
                if (result.ok) {
                    setMentor({
                        ...mentor,
                        img_url_web: result.url,
                        img_url_mobile: result.url,
                    });

                    globalDispatch({
                        type: GActions.SET_SNACKBAR,
                        payload: {
                            message: result.message,
                            severity: "success",
                        },
                    });
                } else {
                    globalDispatch({
                        type: GActions.SET_SNACKBAR,
                        payload: {
                            message: result.message,
                            severity: "error",
                        },
                    });
                }
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    const handleDeleteImage = async () => {
        try {
            const imgurl = mentor.img_url_web;
            if (imgurl) {
                console.log(imgurl, "will be deleted");
                const result = await deleteImage(imgurl);
                console.log(result);
                setMentor({ ...mentor, img_url_web: "", img_url_mobile: "" });
                globalDispatch({
                    type: GActions.SET_SNACKBAR,
                    payload: {
                        message: "Image deleted successfully",
                        severity: "success",
                    },
                });
            }
        } catch (err) {
            console.log(err);
            console.log(err.response);
        }
    };

    return (
        <form
            className="shadow-[0_0_2px_gray] px-4 py-6 rounded-lg mt-4 pl-8"
            onSubmit={onFormSubmit}
        >
            <UpperForm className="mb-6">
                <div style={{ flexGrow: "1", marginTop: "20px" }}>
                    <div className="flex items-center gap-2">
                        <RiAccountCircleLine className="text-3xl text-slate-600" />
                        <input
                            className="w-full border-b-2
                        font-[Outfit] border-gray-100 px-1 py-1 outline-none focus:border-purple-500 text-xl transition-all"
                            type="text"
                            placeholder="Name"
                            value={mentor?.name}
                            required
                            onChange={(e) =>
                                handleInputChange("name", e.target.value)
                            }
                        />
                    </div>
                    <div className="flex items-center gap-2 mt-4">
                        <MdAlternateEmail className="text-3xl text-slate-600" />
                        <input
                            className="w-full border-b-2
                        font-[Outfit] border-gray-100 px-1 py-1 outline-none focus:border-purple-500 text-xl transition-all"
                            type="email"
                            placeholder="Email"
                            value={mentor?.email}
                            required
                            onChange={(e) =>
                                handleInputChange("email", e.target.value)
                            }
                        />
                    </div>
                    <div className="flex items-center gap-2 mt-4">
                        <BsTelephone className="text-3xl text-slate-600" />
                        <input
                            className="w-full border-b-2
                        font-[Outfit] border-gray-100 px-1 py-1 outline-none focus:border-purple-500 text-xl transition-all"
                            placeholder="Phone"
                            value={mentor?.phone}
                            required
                            onChange={(e) =>
                                handleInputChange("phone", e.target.value)
                            }
                        />
                    </div>
                    <div className="flex items-center gap-2 mt-4">
                        <AiOutlineLinkedin className="text-3xl text-slate-600" />
                        <input
                            className="w-full border-b-2
                        font-[Outfit] border-gray-100 px-1 py-1 outline-none focus:border-purple-500 text-xl transition-all"
                            placeholder="Linkedin"
                            value={mentor?.linkedin}
                            required
                            onChange={(e) =>
                                handleInputChange("linkedin", e.target.value)
                            }
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <img
                        onClick={() => setEnlargeImg(!enlargeImg)}
                        src={mentor?.img_url_web || "/avatar.webp"}
                        className={`object-fit cursor-pointer transition-all duration-300 ease-in-out ${
                            enlargeImg
                                ? "rounded-[0px] h-72 w-72"
                                : "rounded-[50%] h-[154px] w-[154px]"
                        }`}
                        alt="plch"
                    />
                    <div className="flex gap-4 mt-3">
                        <button
                            type="button"
                            onClick={onUploadButtonClick}
                            className="px-1.5 py-1 bg-green-400 rounded-md text-sm font-[Outfit] border-transparent border-[1px] hover:border-green-600 active:bg-green-500 hover:shadow-md text-white transition-colors flex items-center gap-1"
                        >
                            <BiUpload className="text-sm" />
                            Upload
                        </button>
                        <button
                            type="button"
                            onClick={handleDeleteImage}
                            className="px-1.5 py-1 bg-red-500 rounded-md text-sm font-[Outfit] text-white active:bg-red-600 hover:shadow-md transition-colors flex items-center gap-1"
                        >
                            <AiOutlineDelete className="text-sm" />
                            Remove
                        </button>
                        <input
                            type="file"
                            className="hidden"
                            accept="image/png, image/jpeg, image/webp"
                            ref={uploadRef}
                            onChange={handleImageChange}
                            onClick={(event) => {
                                event.target.value = "";
                            }}
                        />
                    </div>
                </div>
            </UpperForm>

            {/* <div className="h-[1px] w-full bg-gray-300" /> */}

            <div className="font-[Outfit] text-2xl text-gray-600 font-semibold mt-2">
                Bio
            </div>
            <textarea
                className="w-full border-2 mt-2 rounded-lg
                font-[Outfit] border-gray-300 px-1 py-1 outline-none focus:border-purple-500 text-lg transition-all"
                placeholder="Detailed Summary of mentor's bio ..."
                rows={4}
                required
                value={mentor?.bio}
                onChange={(e) => handleInputChange("bio", e.target.value)}
            ></textarea>

            {state.user?.privilege === "admin" && (
                <>
                    <div className="font-[Outfit] text-2xl text-gray-600 font-semibold mt-2">
                        University
                    </div>
                    <input
                        className="w-full border-2 mt-2 rounded-lg
                font-[Outfit] border-gray-300 px-3 py-1 outline-none focus:border-purple-500 text-lg transition-all"
                        placeholder="University"
                        required
                        value={mentor?.university}
                        onChange={(e) =>
                            handleInputChange("university", e.target.value)
                        }
                    />
                </>
            )}

            <div className="font-[Outfit] text-2xl text-gray-600 font-semibold mt-2">
                {state.user?.privilege === "gateway"? "Service":"Specialization"}
            </div>
            {state.user?.privilege === "gateway"
            ?
            <div className="relative w-full">
            <div
            className="w-full border-2 mt-2 rounded-lg font-[Outfit]  border-gray-300 px-3 py-1 outline-none focus:border-purple-500 text-lg transition-all cursor-pointer"
            onClick={toggleDropdown}
            >
            {mentor.specialization || "Select services"}
            </div>
            {dropdownOpen && (
            <div className="absolute w-full border-2 mt-2 rounded-lg font-[Outfit] border-gray-300 bg-white z-10 h-[150px] overflow-y-auto">
                {['Hiring international education executives', 'Requesting temporary staffing support', 'Requesting a GlobalScope 360 review', 'Advertising with Gateway', 'Finding international education jobs', 'International partnerships and collaborations', 'Other'].map(service => (
                <label key={service} className="block px-3 py-1">
                    <input
                    type="checkbox"
                    value={service}
                    checked={mentor.specialization.split(', ').includes(service)}
                    onChange={handleInputChange1}
                    />
                    {service}
                </label>
                ))}
            </div>
            )}
            </div>
            :
            <input
            className="w-full border-2 mt-2 rounded-lg
            font-[Outfit] border-gray-300 px-3 py-1 outline-none focus:border-purple-500 text-lg transition-all"
            placeholder= "Specialization"
            required
            value={mentor?.specialization}
            onChange={(e) =>
                handleInputChange("specialization", e.target.value)
            }
        />}

            <div className="grid grid-cols-2">
                <div>
                    <div className="font-[Outfit] text-2xl text-gray-600 font-semibold mt-4">
                        {state.user?.privilege === "gateway"? "Topic":"Country"}
                    </div>
                    {state.user?.privilege === "gateway"? 
                <div className="relative w-[98%]">
                <div
                className="w-full border-2 mt-2 rounded-lg font-[Outfit]  border-gray-300 px-3 py-1 outline-none focus:border-purple-500 text-lg transition-all cursor-pointer"
                onClick={toggleDropdown1}
                >
                {mentor.country || "Select topics"}
                </div>
                {dropdownOpen1 && (
                <div className="absolute w-full border-2 mt-2 rounded-lg font-[Outfit] border-gray-300 bg-white z-10 h-[100px] overflow-y-auto">
                    {['Hiring Gateway', 'Advertising with Gateway', 'Affiliating with Gateway', 'Other'].map(service => (
                    <label key={service} className="block px-3 py-1">
                        <input
                        type="checkbox"
                        value={service}
                        checked={mentor.country.split(', ').includes(service)}
                        onChange={handleInputChange2}
                        />
                        {service}
                    </label>
                    ))}
                </div>
                )}
                </div>
                    : <input
                        className="w-[96%] py-1 px-3 border-2 mt-2 border-gray-300 rounded-lg focus:border-purple-400 outline-none"
                        required
                        value={mentor?.country}
                        onChange={(e) =>
                            handleInputChange("country", e.target.value)
                        }
                    />}
                </div>
                <div>
                    {state.user?.privilege === "admin" && (
                        <>
                            <div className="font-[Outfit] text-2xl text-gray-600 font-semibold mt-4">
                                Mentor Group
                            </div>
                            <select
                                className="w-full py-1 px-3 border-2 mt-2 border-gray-300  rounded-lg outline-none "
                                required
                                value={mentor?.university_mentor || ""}
                                onChange={(e) => {
                                    handleInputChange(
                                        "university_mentor",
                                        e.target.value
                                    );
                                    if (state.groups) {
                                        const group = state.groups.find(
                                            (group) =>
                                                group.name === e.target.value
                                        );
                                        if (group) {
                                            handleInputChange(
                                                "university",
                                                group.university
                                            );
                                        }
                                    }
                                }}
                            >
                                <option value="">None</option>
                                {state.groups &&
                                    state.groups.map((lead) => (
                                        <option value={lead.name}>
                                            {lead.name}
                                        </option>
                                    ))}
                            </select>
                        </>
                    )}
                    {state.user?.privilege !== "admin" && (
                        <>
                            <div className="font-[Outfit] text-2xl text-gray-600 font-semibold mt-4">
                                Availability
                            </div>
                            <input
                                className="w-[96%] py-1 px-3 border-2 mt-2 border-gray-300 rounded-lg focus:border-purple-400 outline-none"
                                required
                                value={mentor?.availability}
                                onChange={(e) =>
                                    handleInputChange(
                                        "availability",
                                        e.target.value
                                    )
                                }
                            />
                        </>
                    )}
                </div>
            </div>

            {state.user?.privilege === "admin" && (
                <>
                    <div className="font-[Outfit] text-2xl text-gray-600 font-semibold mt-2">
                        Availability
                    </div>
                    <input
                        className="w-full py-1 px-3 border-2 mt-2 border-gray-300 rounded-lg outline-none focus:border-purple-400"
                        required
                        value={mentor?.availability}
                        onChange={(e) =>
                            handleInputChange("availability", e.target.value)
                        }
                    />
                </>
            )}

            <div className="mt-12">
                <button
                    type="submit"
                    className="px-4 py-2 bg-purple-500 text-white rounded-md text-lg font-[Outfit] active:bg-purple-600 hover:shadow-md transition-colors flex items-center ml-auto"
                >
                    <AiOutlineUserAdd className="mr-2" />
                    {update
                        ? "Update Mentor"
                        : submitting
                        ? "Submitting"
                        : "Add Mentor"}
                </button>
            </div>
        </form>
    );
};

export default NewMentorForm;
