import { useState } from "react";
import { TextField } from "@mui/material";
import {
  ForgotLink,
  LoginForm,
  LoginSubtitle,
  LoginTitle,
  SubmitButton,
} from "./items";
import { ButtonLoading } from "./ActualLoginForm";

const SendPasswordResetEmail = ({ onSubmit = async () => {} }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    email: "",
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    await onSubmit(email, setError, setLoading);
  };

  return (
    <LoginForm onSubmit={handleFormSubmit}>
      <LoginTitle>Reset Password</LoginTitle>
      <LoginSubtitle>
        Enter email address of your registered account. A 6 digit verification
        code will be sent on that email.
      </LoginSubtitle>
      <TextField
        fullWidth
        margin="dense"
        label="Email"
        color="secondary"
        type="email"
        variant="standard"
        error={error.email !== ""}
        helperText={error.email}
        value={email}
        onChange={handleEmailChange}
        sx={{ fontFamily: "Outfit" }}
        required
      />
      {loading ? (
        <ButtonLoading />
      ) : (
        <SubmitButton
          type="submit"
          disabled={loading}
          style={{ marginTop: "1.5rem" }}
        >
          Submit
        </SubmitButton>
      )}
      <div
        style={{
          marginTop: "2rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!loading && <ForgotLink to="/login">Login</ForgotLink>}
      </div>
    </LoginForm>
  );
};

export default SendPasswordResetEmail;
