import React, { useContext } from "react";
import { Stack, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import linkedinlogo from "./linkedinlogo.svg";
import SessionInfoContext from "../contexts/SessionsContext";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  const isMobile = useContext(SessionInfoContext);
  const disclaimerWidth = isMobile ? "80%" : "50%";

  return (
    <Stack alignItems="center" sx={{ justifyContent: "center" }}>
      <Typography
        variant="body2"
        sx={{ fontSize: "1.1vw", fontWeight: "bold" }}
        align="center"
        gutterBottom
        marginBottom={1}
      >
        ©2021 by CollegeIt
      </Typography>
      <div align="center">
        <Link
          align="center"
          target="_blank"
          color="#000000"
          href="https://www.linkedin.com/company/collegeit/about/?viewAsMember=true"
          gutterBottom
        >
          <LinkedInIcon />
        </Link>
        <Link
          sx={{ pl: 2, display: "inline-flex" }}
          align="center"
          target="_blank"
          color="#000000"
          href="https://www.youtube.com/channel/UCd5Tg5sgfRppYhxv-ImVvPg"
          gutterBottom
        >
          <YouTubeIcon />
        </Link>
        <Link
          sx={{ pl: 2, display: "inline-flex" }}
          align="center"
          target="_blank"
          color="#000000"
          href="https://instagram.com/collegeit_?utm_medium=copy_link"
          gutterBottom
        >
          <InstagramIcon />
        </Link>
        <Link
          sx={{ pl: 2, display: "inline-flex" }}
          align="center"
          target="_blank"
          color="#000000"
          href="mailto:sanchit@collegeit.org"
          gutterBottom
        >
          <MailOutlineIcon justifyContent="center" />
        </Link>
      </div>
      <Stack direction="row" spacing={3} marginBottom={4} marginTop={2}>
        <Link
          target="_blank"
          component={RouterLink}
          to="/privacy"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2" sx={{ fontSize: "1.1vw" }}>
            Privacy Policy
          </Typography>
        </Link>
        <Link
          target="_blank"
          href="https://www.m.collegeit.org/about"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2" sx={{ fontSize: "1.1vw" }}>
            About
          </Typography>
        </Link>
        <Link
          sx={{ textDecoration: "none" }}
          align="center"
          target="_blank"
          href="mailto:info@collegeit.org"
        >
          <Typography variant="body2" sx={{ fontSize: "1.1vw" }}>
            Contact
          </Typography>
        </Link>
        <Link
          target="_blank"
          component={RouterLink}
          to="/tos"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2" sx={{ fontSize: "1.1vw" }}>
            Terms of service
          </Typography>
        </Link>
      </Stack>
      <br />
      <br />
    </Stack>
  );
};

export default Footer;
