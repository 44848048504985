import DashboardElements from "./DashboardElements"; 
import StatsBox from "./StatsBox";
import GetStatsData from "./GetStatsData";
const StatsContainer = () => {

    const arr=GetStatsData();
    return ( 
        <DashboardElements.StatsBoxGrid>
{
    arr.map((details,id)=>{
        return (
            <StatsBox title={details.title} count={details.count} percentageChange={details.percentageChange} key={id} />
        )
    })
}
        </DashboardElements.StatsBoxGrid>
     );
}
 
export default StatsContainer;