import styled from "@emotion/styled";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Button, Card } from "@mui/material";

export const Container = styled.div`
    padding: 2rem 3rem;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Heading = styled.h1`
    color: #222;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 

`;

export const ExportButton = styled(Button)`
    background-color: rgba(72, 128, 255, 1);
    text-transform: none;
    color: white;
    font-family: 'Nunito Sans', sans-serif;
    transition: 250ms;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight:600;
    

    &:hover {
        background-color: rgb(72, 128, 255);
    }
`;

const Wrapper = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 3rem;
    margin-top:1.15rem;
`;

const Row = styled.tr`
    cursor: pointer;
    transition: 250ms;


    &:nth-of-type(odd) {
        background: rgba(238, 242, 245,0.5);
    }

    &:hover {
        background-color: rgba(238, 242, 255, 1);
    }
`;

const Head = styled.th`
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-align: center;

    color: #878787;
    text-align: start;
    padding: 0.6rem 1rem;
    padding-bottom: 1.1rem;
`;

const Data = styled.td`
    padding: 0.8rem 1rem;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
`;

export const Table = {
    Wrapper,
    Row,
    Head,
    Data,
};

export const UniWrapper = styled.div`
    display: inline-block;
    padding: 0.1rem 0.4rem;
    position: relative;
    font-family: consolas;
    background-color: #eee;
    font-family: 14px;

    margin: 2px 0px;
    border-radius: 3px;
    user-select: none;
`;

const RoundedBorder = styled(Button)`
    border-radius: 999px;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(189, 193, 202, 1);
    background-color: white;
    cursor: pointer;
    transition: 250ms;
    min-width: 0;
    color: rgba(189, 193, 202, 1);

    &:hover {
        background-color: #5f00d8;
        border-color: #5f00d8;
        color: white;
    }
`;

const Field = styled.span`
    background: #eef2f5;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    user-select: none;
`;

export const Pagination = {
    Wrapper: styled.div`
        display: flex;
        position: fixed;
        bottom: 1rem;
        background-color: white;
        padding: 0.5rem 1rem;
        align-items: center;
        justify-content: center;
        left: 50%;
        gap: 20px;
        border-radius: 1rem;
        box-shadow: 0 0 1px black;
        transition: 250ms;

        &:hover {
            box-shadow: 0 0 6px gray;
        }
    `,

    Container: styled.div`
        background: red;
    `,
    LeftArrow: ({ onClick }) => {
        return (
            <RoundedBorder onClick={onClick}>
                <ArrowBackIosNew style={{ fontSize: "16px" }} />
            </RoundedBorder>
        );
    },
    RightArrow: ({ onClick }) => {
        return (
            <RoundedBorder onClick={onClick}>
                <ArrowForwardIos style={{ fontSize: "16px" }} />
            </RoundedBorder>
        );
    },
    CurrentPage: ({ page }) => <Field>{page}</Field>,
    TotalPage: ({ page }) => <Field>{page}</Field>,
};

export const UniversityCellCollapsed = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-evenly;
`;

export const MoreUniversities = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(175.93deg, #5f00d8 -10.23%, #b200f1 117.03%);
    color: white;
    border-radius: 999px;
    flex-grow: 0;
    padding: 0.3rem;
    font-size: 10px;
    position: absolute;
    top: -10px;
    right: -10px;
`;

export const DialogContainer = styled(Card)`
    position: absolute;
    width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: white;
    padding: 2rem 3rem;
    padding-bottom: 0.4rem;
`;
