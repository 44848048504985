import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import moment from "moment/moment";
import React from "react";
import { FiMoreVertical } from "react-icons/fi";

const MentorCard = ({ showUni = true, mentor, onClick }) => {
    return (
        <Cover onClick={onClick}>
            <ProfilePic src={mentor?.img_url_web || "/avatar.webp"} alt="img" />
            <Details>
                <Name>{mentor?.name}</Name>
                <Specialization>{mentor?.specialization}</Specialization>
                {!showUni ? (
                    <hr />
                ) : (
                    <Specialization
                        style={{ color: "black", marginTop: "8px" }}
                    >
                        {mentor?.university?.length > 30
                            ? mentor?.university?.slice(0, 27) + "..."
                            : mentor?.university}
                    </Specialization>
                )}
                {false && (
                    <Specialization
                        style={{
                            bottom: "7px",
                            right: "10px",
                            position: "absolute",
                            fontStyle: "italic",
                            fontSize: "12px",
                        }}
                    >
                        Last active {moment(mentor.lastTimestamp).fromNow()}
                    </Specialization>
                )}
            </Details>
            <IconButton
                style={{ position: "absolute", top: "2px", right: "2px" }}
            >
                <FiMoreVertical style={{ fontSize: "16px" }} />
            </IconButton>
        </Cover>
    );
};

const Cover = styled.div`
    background: #FFF;
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
    padding: 12px 18px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    transition: 100ms all ease-out;
    user-select: none;
    &:hover {
        box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        background: #fafafa;
    }
    &:active {
        transform: scale(0.99);
    }
`;

const ProfilePic = styled.img`
    width: 90px;
    height: 90px;
    border-radius: 50%;
`;

const Details = styled.div`
    display: flex;
    margin-left: 6px;
    flex-direction: column;
    padding: 4px 0;
    gap: 3px;
`;

const Name = styled.span`
    font-size: 18px;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
`;

const Specialization = styled.span`
    font-size: 14px;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    color: #7a7a7a;
`;

export default MentorCard;
