import React, { useState } from 'react'
import { getConvs } from '../../queries/conv';
import { Configuration, OpenAIApi } from 'openai';
    
const TestConv = () => {
    const configuration = new Configuration({
      apiKey: "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",
    });
    const openai = new OpenAIApi(configuration);
    const [conversation, setConversation] = useState("")
    
    const generate = (text) => {
        const words = 100
        return `this is a chat between a student already studying in UCLA university and a student who wants to join this university, summarise this chat in long detail in ${words} words:\n\n${text}`;
    }

    const generateResponse = async (text) => {
        if (!configuration.apiKey) {
            return {
                error: {
                    message: "OpenAI API key not configured, please follow instructions in README.md",
                }
            };
        }

        try {
            const response = await openai.createCompletion({
                model: "text-davinci-003",
                prompt: generate(text),
                temperature: 0.5,
                max_tokens: 350,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
            });
            const result = response.data.choices[0].text;
            return result;
        } catch (error) {
            // Consider adjusting the error handling logic for your use case
            if (error.response) {
                console.error(error.response.status, error.response.data);
                return error.response.data;
            } else {
                console.error(`Error with OpenAI API request: ${error.message}`);
                return {
                    error: {
                        message: 'An error occurred during your request.',
                    }
                };
            }
        }
    }

    const testFn = async () => {
        console.log(configuration)
        
        const result = await getConvs()
        let resultArray = JSON.stringify(result)
        resultArray = Object.values(result)
        // setConversation(resultArray[108])
        const resultAI = await generateResponse(resultArray[108])
        console.log(JSON.stringify(resultAI) + " aiiii");
        setConversation(resultAI)
    }
    return (
        <div>
            <button
                style={{
                    backgroundColor: "red",
                    padding: "8px",
                    borderRadius: "4px",
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "bold",
                    ":hover": { backgroundColor: "black" },
                }}
                onClick={testFn}
            >
                Fetch my conversation
            </button>
          
            <p style={{ fontSize: "20px", padding: "4px" }}>{conversation}</p>
        </div>
    )
}

export default TestConv