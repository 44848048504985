import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { getGroup } from "../../queries/groups";
import { Container, Header, Heading } from "../Overview/OverviewElements";
import { BackButton } from "../Mentors/NewMentor";
import { PersonAddAlt1 } from "@mui/icons-material";
import { Button, Chip } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { CardContainer } from "../Mentors";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { getMentors } from "../../queries/mentor";
import MentorCard from "../Mentors/MentorCard";
import UserTable from "./UserTable";

const GroupDetail = () => {
    const { group: groupId } = useParams();

    const [loading, setLoading] = React.useState(true);
    const [group, setGroup] = React.useState({});

    const [state, dispatch] = useGlobalContext();
    const history = useHistory();

    React.useEffect(() => {
        setLoading(true);
        getGroup(groupId)
            .then((res) => {
                console.log(res);
                setGroup(res.group);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [groupId]);

    React.useEffect(() => {
        if (group) {
            if (!state.mentors) {
                dispatch({
                    type: GActions.SET_MENTORS_LOADING,
                    payload: true,
                });
                getMentors()
                    .then((data) => {
                        let sorted = data.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else return 0;
                        });
                        dispatch({
                            type: GActions.SET_MENTORS,
                            payload: sorted,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        dispatch({
                            type: GActions.SET_MENTORS_LOADING,
                            payload: false,
                        });
                    });
            }
        }
    }, [group]);

    return (
        <Container>
            <Header>
                <Heading>
                    <BackButton link="/manage" />
                    Group Detail {loading ? "..." : "- " + group?.name}
                </Heading>

                <div className="flex">
                    {!loading && (
                        <Button
                            variant="contained"
                            startIcon={<PersonAddAlt1 />}
                            style={{
                                fontFamily: "Outfit",
                                textTransform: "capitalize",
                                marginRight: "10px",
                                justifyContent: "start",
                                background: "green",
                            }}
                            onClick={() => {
                                history.push("/manage/new/user");
                            }}
                        >
                            New User
                        </Button>
                    )}
                </div>
            </Header>
            {!loading && (
                <div className="mt-2">
                    <Chip
                        label={group?.university}
                        style={{
                            fontFamily: "Outfit",
                            background: "#5555d6",
                            color: "white",
                            height: "24px",
                            marginLeft: "24px",
                        }}
                        icon={
                            group?.university === "CollegeIt" ? (
                                <AdminPanelSettingsIcon
                                    style={{
                                        color: "white",
                                        fontSize: 20,
                                        paddingLeft: "3px",
                                    }}
                                />
                            ) : (
                                <AccountBalanceIcon
                                    style={{
                                        color: "white",
                                        fontSize: 18,
                                        paddingLeft: "3px",
                                    }}
                                />
                            )
                        }
                    />

                    <div className="mt-4"></div>

                    <UserTable leads={group.accounts} />

                    <div className="mt-6 font-[Outfit] font-medium text-2xl">
                        Mentors
                    </div>

                    <CardContainer>
                        {state.mentorsLoading
                            ? "Loading..."
                            : state.mentors?.filter(
                                  (m) => m.university_mentor === group.name
                              ).length === 0
                            ? "No mentors found"
                            : state.mentors
                                  .filter(
                                      (m) => m.university_mentor === group.name
                                  )
                                  .map((mentor) => {
                                      return (
                                          <MentorCard
                                              key={mentor._id}
                                              mentor={mentor}
                                              onClick={() =>
                                                  history.push(
                                                      "/mentors/" + mentor?._id
                                                  )
                                              }
                                          />
                                      );
                                  })}
                    </CardContainer>
                </div>
            )}
        </Container>
    );
};

export default GroupDetail;
